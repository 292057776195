import React from "react";
import { useFormContext, ErrorMessage } from "react-hook-form";
import classNames from "classnames";
import { HelpText, ErrorComponent } from "js/components/common/Widgets";

import { getUniqueId } from "js/components/common/helpers";

const CheckboxField = ({ name, label, helpText, autofocus }) => {
  const { register, errors, formState } = useFormContext();

  const inputId = `${name}_${getUniqueId()}`;
  return (
    <div className="field">
      <input
        id={inputId}
        name={name}
        type="checkbox"
        className={classNames("tw-form-checkbox", {
          "is-danger": errors[name] && formState.touched[name]
        })}
        autoFocus={autofocus}
        ref={register}
      />
      <label htmlFor={name} className="label">
        {label}
      </label>
      <ErrorMessage name={name} errors={errors} as={ErrorComponent} />
      <HelpText text={helpText} />
    </div>
  );
};

export default CheckboxField;
