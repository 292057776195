import React, { useContext, useState } from "react";

import ReactPaginate from "react-paginate";
import WindowContext from "./WindowContext";

export default ({ pageNum, numPages, onPageChange }) => {
  const screenSizes = useContext(WindowContext);

  return (
    <ReactPaginate
      pageCount={numPages}
      pageRangeDisplayed={screenSizes.tabletUp ? 3 : 2}
      marginPagesDisplayed={screenSizes.tabletUp ? 2 : 1}
      previousLabel={"<"}
      nextLabel={">"}
      breakLabel={"⋯"}
      initialPage={pageNum - 1}
      forcePage={pageNum - 1}
      disableInitialCallback={true}
      containerClassName={"searchpage__pagination"}
      pageLinkClassName={"link page"}
      breakClassName={"link break"}
      activeLinkClassName={"active"}
      previousLinkClassName={"link previous"}
      nextLinkClassName={"link next"}
      onPageChange={onPageChange}
    ></ReactPaginate>
  );
};
