import React from "react";
import { pure, compose } from "recompose";

const InfluencerProfilePure = ({
  organization,
  permissions: { is_authenticated },
  urls
}) => (
  <>
    <div className="tw-my-4 tw-p-4 tw-border tw-rounded tw-border-gray-400">
      <div className="profilepage__info-heading">
        Blogger & Influencer Brief
      </div>

      <div className="profilepage__info">
        <div className="profilepage__info-key">Who we are</div>
        <div className="profilepage__info-value">
          <span>{organization.description}</span>
        </div>
      </div>

      {!!is_authenticated && (
        <>
          <div className="profilepage__info">
            <div className="profilepage__info-key">
              Product certifications we support
            </div>
            <div className="profilepage__info-value">
              <span className="tw-tag-list">
                {organization.product_certifications &&
                  organization.product_certifications.map(cert => (
                    <span key={cert.id} className="tw-tag tw-bg-teal-600">
                      {cert.name}
                    </span>
                  ))}
              </span>
            </div>
          </div>
          <div className="profilepage__info">
            <div className="profilepage__info-key">
              Company values we support
            </div>
            <div className="profilepage__info-value">
              <span className="tw-tag-list">
                {organization.certifications &&
                  organization.certifications.map(cert => (
                    <span key={cert.id} className="tw-tag">
                      {cert.name}
                    </span>
                  ))}
              </span>
            </div>
          </div>
        </>
      )}
    </div>

    <div className="tw-my-4 tw-p-4 tw-border tw-rounded tw-border-gray-400 tw-relative">
      {!is_authenticated && (
        <div className="profilepage__overlay">
          <a
            href={urls.join}
            className="profilepage__overlay__text tw-text-2xl tablet-up:tw-text-4xl tw-text-gray-700 tw-font-semibold"
          >
            Join Parsnip to see more!
          </a>
        </div>
      )}
      <div className="profilepage__info-heading">Reach out to us for</div>

      <div className="profilepage__info">
        <div className="profilepage__info-key">Content focus</div>
        <div className="profilepage__info-value">
          <span className="tw-tag-list">
            {organization.offers &&
              organization.offers.map(tag => (
                <span key={tag.id} className="tw-tag tw-bg-blue-600">
                  {tag.name}
                </span>
              ))}
          </span>
        </div>
      </div>
      <div className="profilepage__info">
        <div className="profilepage__info-key">Topic focus</div>
        <div className="profilepage__info-value">
          <span className="tw-tag-list">
            {organization.topics &&
              organization.topics.map(tag => (
                <span key={tag.id} className="tw-tag tw-bg-blue-600">
                  {tag.name}
                </span>
              ))}
          </span>
        </div>
      </div>
    </div>
  </>
);

const InfluencerProfile = compose(pure)(InfluencerProfilePure);

export default InfluencerProfile;
