import React, { useState, useCallback } from "react";
import { ErrorMessage } from "formik";
import classNames from "classnames";
import Select from "react-select";
import Async from "react-select/async";
import { css } from "@emotion/core";
import prependHttp from "prepend-http";
import dateFnsFormat from "date-fns/format";
import dateFnsParse from "date-fns/parse";
import parseISO from "date-fns/parseISO";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import Cleave from "cleave.js/react";
import { TagList } from "js/components/common/EditableField";

const ErrorComponent = ({ children }) => (
  <span className="help is-danger tw-font-normal tw-text-left">{children}</span>
);

const BulmaFieldArray = ({ name, label, helpText, options, form }) => (
  <div className="field">
    <label className="label">{label}</label>
    {helpText && <div className="help tw-font-normal tw-mb-4">{helpText}</div>}
    <ErrorMessage name={name} component={ErrorComponent} />
    <TagList name={name} options={options} value={form.values[name]} />
  </div>
);

const BulmaTextField = ({
  field,
  type,
  form: { errors, touched, setFieldValue, setFieldTouched },
  label,
  helpText,
  placeholder,
  prefix,
  autofocus
}) => {
  let [prefixWidth, setPrefixWidth] = useState(0);
  const sizeRef = useCallback(node => {
    if (node !== null) {
      setPrefixWidth(node.getBoundingClientRect().width);
    }
  }, []);

  // Inline style sets input padding to prefix width or the default Bulma padding value
  return (
    <div className="field">
      <label className="label">{label}</label>
      <div className="tw-relative">
        {prefix && (
          <div
            ref={sizeRef}
            className="tw-absolute tw-flex tw-items-center tw-h-full tw-pl-2 tw-z-10 tw-text-gray-500 tw-font-semibold"
          >
            <span>{prefix}</span>
          </div>
        )}
        <input
          type={type || "text"}
          className={classNames("tw-input", {
            "is-error": errors[field.name] && touched[field.name]
          })}
          style={{ paddingLeft: prefixWidth || "1rem" }}
          {...field}
          placeholder={placeholder}
          autoFocus={autofocus}
          onBlur={e => {
            if (type === "url") {
              if (!!field.value) {
                const updatedValue = prependHttp(field.value);

                // Have to do some magic to get Formik to validate the new, updated value
                // See the following issue: https://github.com/jaredpalmer/formik/issues/2106
                setFieldValue(
                  field.name,
                  updatedValue,
                  true /* force validation */
                );
                setFieldTouched(
                  field.name,
                  true,
                  false /* force abort validation */
                );
              }
            }
          }}
        />
        <ErrorMessage name={field.name} component={ErrorComponent} />
        {helpText && (
          <div className="help tw-font-normal tw-mb-4 tw-text-left">
            {helpText}
          </div>
        )}
      </div>
    </div>
  );
};

const BulmaTextAreaField = ({
  field,
  form: { errors, touched },
  label,
  rows,
  placeholder,
  autofocus
}) => (
  <div className="field">
    <label className="label">{label}</label>

    <textarea
      className={classNames("tw-input", {
        "is-danger": errors[field.name] && touched[field.name]
      })}
      {...field}
      placeholder={placeholder}
      autoFocus={autofocus}
      rows={rows}
    />
    <ErrorMessage name={field.name} component={ErrorComponent} />
  </div>
);

const BulmaCurrencyField = ({
  field,
  form: { setFieldValue, errors, touched },
  label,
  placeholder
}) => {
  return (
    <div className="field">
      <label className="label">{label}</label>

      <Cleave
        options={{
          prefix: "$",
          numeral: true,
          numeralPositiveOnly: true,
          numeralDecimalScale: 0,
          numeralThousandsGroupStyle: "thousand",
          rawValueTrimPrefix: true
        }}
        className={classNames("tw-input", {
          "is-danger": errors[field.name] && touched[field.name]
        })}
        onChange={e => setFieldValue(field.name, e.target.rawValue)}
        value={field.value}
      />
      <ErrorMessage name={field.name} component={ErrorComponent} />
    </div>
  );
};

const reactSelectCustomStyles = {
  control: (provided, state) => {
    // NOTE: we're duplicating the .no-focus-outline class and the focus outline style from _base.scss
    const focusOutline = !document.body.classList.contains("no-focus-outline");

    const customClass = {
      outline: state.isFocused && focusOutline ? "3px auto #2646f6" : "",
      outline:
        state.isFocused && focusOutline
          ? "5px auto -webkit-focus-ring-color"
          : "",
      boxShadow: "none",
      border: "1px transparent",
      fontWeight: "300",
      backgroundColor: "#f1f5f8",
      borderRadius: "0.5rem",
      padding: "0.5rem 1rem",
      lineHeight: "1.5"
    };
    return {
      ...provided,
      ...customClass
    };
  },
  valueContainer: (provided, state) => ({
    ...provided,
    display: "flex",
    padding: "0"
  }),
  multiValue: (provided, state) => ({
    ...provided,
    borderRadius: "9999px",
    overflow: "hidden",
    fontWeight: "800",
    textTransform: "uppercase",
    fontSize: "0.75rem",
    letterSpacing: "1.5px"
    // paddingTop: "0.2rem",
    // paddingBottom: "0.2rem"
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    paddingLeft: "1rem",
    paddingRight: "0.25rem",
    paddingTop: "0.25rem",
    paddingBottom: "0.25rem"
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    paddingRight: "0.5rem",
    paddingTop: "0.25rem",
    paddingBottom: "0.25rem",
    color: state.isHovered ? "#fc4349" : ""
  }),
  input: (provided, state) => ({
    ...provided,
    flexGrow: "1"
  }),
  clearIndicator: (provided, state) => ({
    ...provided,
    padding: "0 0 0 0.5rem"
  })
};
const BulmaSelectField = ({
  field,
  form: { errors, touched, setFieldValue, setFieldTouched },
  label,
  options,
  multiple,
  autofocus,
  helpText
}) => (
  <div className="field">
    <label className="label">{label}</label>
    {helpText && <div className="help tw-font-normal tw-mb-4">{helpText}</div>}

    <Select
      options={options}
      isMulti={multiple}
      closeMenuOnSelect={!multiple}
      autoFocus={autofocus}
      getOptionLabel={option => option.name}
      getOptionValue={option => option.slug}
      onChange={value => {
        setFieldValue(field.name, value);
      }}
      onBlur={() => setFieldTouched(field.name, true)}
      value={field.value}
      className={classNames("tw-font-normal", {
        "is-danger": errors[field.name] && touched[field.name]
      })}
      styles={reactSelectCustomStyles}
    />
    <ErrorMessage name={field.name} component={ErrorComponent} />
  </div>
);

const BulmaAsyncSelectField = ({
  field,
  form: { errors, touched, setFieldValue, setFieldTouched },
  label,
  fetchOptions,
  multiple,
  autofocus,
  helpText
}) => (
  <div className="field">
    <label className="label">{label}</label>
    {helpText && <div className="help tw-font-normal tw-mb-4">{helpText}</div>}

    <Async
      loadOptions={fetchOptions}
      isMulti={multiple}
      closeMenuOnSelect={true}
      autoFocus={autofocus}
      openMenuOnClick={false}
      getOptionLabel={option => option.name}
      getOptionValue={option => option.slug}
      onChange={value => {
        setFieldValue(field.name, value);
      }}
      onBlur={() => setFieldTouched(field.name, true)}
      value={field.value}
      className={classNames("tw-font-normal", {
        "is-danger": errors[field.name] && touched[field.name]
      })}
      styles={reactSelectCustomStyles}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null
      }}
    />
    <ErrorMessage name={field.name} component={ErrorComponent} />
  </div>
);

const BulmaCheckboxField = ({
  field,
  form: { errors, touched },
  label,
  autofocus
}) => (
  <div className="field">
    <input
      id={field.name}
      type="checkbox"
      className="tw-input"
      autoFocus={autofocus}
      className={classNames({
        "is-danger": errors[field.name] && touched[field.name]
      })}
      checked={field.value}
      {...field}
    />
    <label htmlFor={field.name} className="label">
      {label}
    </label>
    <ErrorMessage name={field.name} component={ErrorComponent} />
  </div>
);

const BulmaYearField = ({
  field,
  form: { errors, touched },
  label,
  autofocus
}) => (
  <div className="field">
    <label className="label">{label}</label>

    <input
      type="number"
      min={1200}
      max={new Date().getFullYear()}
      step="1"
      autoFocus={autofocus}
      className={classNames("tw-input", {
        "is-danger": errors[field.name] && touched[field.name]
      })}
      value={field.value || ""}
      name={field.name}
      onChange={field.onChange}
      onBlur={field.onBlur}
    />
    <ErrorMessage name={field.name} component={ErrorComponent} />
  </div>
);

class DateInput extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <input
        type="text"
        value={this.props.value}
        className={classNames("tw-input", {
          "is-danger":
            this.props.errors[this.props.name] &&
            this.props.touched[this.props.name]
        })}
        autoComplete="off"
        onChange={this.props.onChange}
        onFocus={this.props.onFocus}
        onKeyUp={this.props.onKeyUp}
        onClick={this.props.onClick}
        onBlur={this.props.onBlur}
      />
    );
  }
}
function parseDate(str, format, locale) {
  const parsed = dateFnsParse(str, format, new Date(), { locale });
  if (DateUtils.isDate(parsed)) {
    return parsed;
  }
  return undefined;
}
function formatDate(date, format, locale) {
  return dateFnsFormat(date, format, { locale });
}
const BulmaDateField = ({
  field,
  form: { errors, touched, setFieldValue },
  label
}) => {
  const FORMAT = "yyyy/MM/dd";
  let value = field.value;
  if (typeof value === "string" || value instanceof String) {
    value = parseISO(value);
  }
  return (
    <div className="field">
      <label className="label">{label}</label>

      <DayPickerInput
        inputProps={{ errors, touched }}
        value={value ? dateFnsFormat(value, FORMAT) : ""}
        onDayChange={(day, modifiers, dayPickerInput) => {
          {
            setFieldValue(field.name, day);
          }
        }}
        component={DateInput}
        parseDate={parseDate}
        formatDate={formatDate}
        format={FORMAT}
        placeholder={`${dateFnsFormat(new Date(), FORMAT)}`}
        dayPickerProps={{ disabledDays: { before: new Date() } }}
      />
      <ErrorMessage name={field.name} component={ErrorComponent} />
    </div>
  );
};

export {
  BulmaTextField,
  BulmaTextAreaField,
  BulmaCheckboxField,
  BulmaYearField,
  BulmaDateField,
  BulmaCurrencyField,
  BulmaSelectField,
  BulmaAsyncSelectField,
  BulmaFieldArray,
  ErrorComponent
};
