import React, { useState, useEffect } from "react";
import WindowContext from "./WindowContext";

// TODO: Use resolveConfig from tailwind once the following issue is fixed:
// https://github.com/tailwindcss/discuss/issue
const breakpoints = {
  phoneUp: 0,
  tabletUp: 600,
  laptopUp: 900,
  desktopUp: 1200
};

// Return an object that looks like:
// const value = {
//   "phoneUp": true,
//   "tabletUp": true,
//   "laptopUp": false,
//   "desktopUp": false
// }
export default props => {
  const setResponsive = size => {
    const value = {};
    Object.keys(breakpoints).map(bp => {
      value[bp] = size > breakpoints[bp];
    });
    return value;
  };

  const [sizes, setSizes] = useState(setResponsive(window.innerWidth));

  useEffect(() => {
    const handleResize = () => {
      setSizes(setResponsive(window.innerWidth));
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <WindowContext.Provider value={sizes}>
      {props.children}
    </WindowContext.Provider>
  );
};
