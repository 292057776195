import React from "react";
import { useFormContext, ErrorMessage, Controller } from "react-hook-form";
import classNames from "classnames";
import Select from "react-select";
import Async from "react-select/async";
import { HelpText, ErrorComponent } from "js/components/common/Widgets";

import { getUniqueId } from "js/components/common/helpers";

const reactSelectCustomStyles = {
  control: (provided, state) => {
    // NOTE: we're duplicating the .no-focus-outline class and the focus outline style from _base.scss
    const focusOutline = !document.body.classList.contains("no-focus-outline");

    const customClass = {
      outline: state.isFocused && focusOutline ? "3px auto #2646f6" : "",
      outline:
        state.isFocused && focusOutline
          ? "5px auto -webkit-focus-ring-color"
          : "",
      boxShadow: "none",
      border: "1px transparent",
      fontWeight: "300",
      backgroundColor: "#f1f5f8",
      borderRadius: "0.5rem",
      padding: "0.5rem 1rem",
      lineHeight: "1.5"
    };
    return {
      ...provided,
      ...customClass
    };
  },
  valueContainer: (provided, state) => ({
    ...provided,
    display: "flex",
    padding: "0"
  }),
  multiValue: (provided, state) => ({
    ...provided,
    borderRadius: "9999px",
    overflow: "hidden",
    fontWeight: "800",
    textTransform: "uppercase",
    fontSize: "0.75rem",
    letterSpacing: "1.5px"
    // paddingTop: "0.2rem",
    // paddingBottom: "0.2rem"
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    paddingLeft: "1rem",
    paddingRight: "0.25rem",
    paddingTop: "0.25rem",
    paddingBottom: "0.25rem"
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    paddingRight: "0.5rem",
    paddingTop: "0.25rem",
    paddingBottom: "0.25rem",
    color: state.isHovered ? "#fc4349" : ""
  }),
  input: (provided, state) => ({
    ...provided,
    flexGrow: "1"
  }),
  clearIndicator: (provided, state) => ({
    ...provided,
    padding: "0 0 0 0.5rem"
  })
};

const WrappedSelect = ({ value, options, onChange, multiple, className }) => {
  return (
    <Select
      options={options}
      value={value}
      getOptionLabel={option => option.name}
      getOptionValue={option => option.slug}
      onChange={value => onChange(value)}
      isMulti={multiple}
      closeMenuOnSelect={!multiple}
      className={className}
      styles={reactSelectCustomStyles}
    />
  );
};

const SelectField = ({ name, label, helpText, options, multiple }) => {
  const { errors, watch, formState } = useFormContext();
  const inputId = `${name}_${getUniqueId()}`;

  const formValue = watch(name);
  let value = null;

  // Alter the value to be what react-select expects (both for single and multi)
  if (multiple) {
    value = Array.isArray(formValue) ? formValue : [formValue];
    value = value.map(val => options.find(option => option.slug === val));
  } else {
    if (typeof formValue === "string" || formValue instanceof String) {
      value = options.find(option => option.slug === formValue);
    }
  }

  // Note: we have to set valueName to something other than `value` so that
  // RHF Controller won't clobber the value in the native input
  // https://github.com/react-hook-form/react-hook-form/issues/997#issuecomment-590124001
  return (
    <div className="field">
      <label htmlFor={inputId} className="label">
        {label}
      </label>
      <HelpText text={helpText} />
      <Controller
        id={inputId}
        name={name}
        valueName="anythingotherthanvalue"
        onChange={([selected]) => {
          let returnValue = null;
          if (multiple) {
            returnValue = { value: selected.map(sel => sel.slug) };
          } else {
            returnValue = { value: selected.slug };
          }
          return returnValue;
        }}
        as={
          <WrappedSelect
            options={options}
            value={value}
            multiple={multiple}
            className={classNames("tw-font-normal", {
              "is-danger": errors[name] && formState.touched[name]
            })}
          />
        }
      />
      <ErrorMessage name={name} errors={errors} as={ErrorComponent} />
    </div>
  );
};

const AsyncSelectField = ({
  name,
  label,
  helpText,
  fetchOptions,
  multiple
}) => {
  const { errors, formState } = useFormContext();
  const inputId = `${name}_${getUniqueId()}`;
  return (
    <div className="field">
      <label htmlFor={inputId} className="label">
        {label}
      </label>
      <HelpText text={helpText} />
      <Controller
        id={inputId}
        name={name}
        onChange={([selected]) => {
          // React Select return object instead of value for selection
          return { value: selected };
        }}
        as={
          <Async
            loadOptions={fetchOptions}
            isMulti={multiple}
            closeMenuOnSelect={true}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.slug}
            className={classNames("tw-font-normal", {
              "is-danger": errors[name] && formState.touched[name]
            })}
            styles={reactSelectCustomStyles}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null
            }}
          />
        }
      />
      <ErrorMessage name={name} errors={errors} as={ErrorComponent} />
    </div>
  );
};

export { SelectField, AsyncSelectField };
