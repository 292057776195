import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "js/components/common/axios";
import debounce from "lodash/debounce";
import pick from "lodash/pick";
import merge from "lodash/merge";
import Dropzone from "react-dropzone";

import {
  BulmaTextField,
  BulmaTextAreaField,
  BulmaDateField,
  BulmaCurrencyField,
  BulmaFieldArray,
  BulmaAsyncSelectField,
  BulmaCheckboxField,
  ErrorComponent
} from "js/components/common/BulmaFormField";

import { useApiObject } from "js/components/common/helpers";
import plusIcon from "images/profile/plus.svg";

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  url: Yup.string().url(),
  description: Yup.string().required(),
  capacity: Yup.number().integer(),
  start: Yup.date().required(),
  budget: Yup.number(),
  location: Yup.string(),
  offers_text: Yup.string(),
  products: Yup.array(),
  audiences: Yup.array().min(1),
  asks: Yup.array(),
  consent: Yup.boolean()
});

const Update = props => {
  const [event, updateEvent] = useApiObject(props.event);

  const handleSubmit = async (values, actions) => {
    // Send JSON and File data in separate requests
    // TODO: centralize this - also exists in Profile
    let fileData = new FormData();
    let hasFileData = false;
    const fileKeys = ["banner"];
    fileKeys.map(key => {
      if (values[key] instanceof File) {
        fileData.append(key, values[key]);
        hasFileData = true;
      }
      delete values[key];
    });
    const config = {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    };

    try {
      const response = await axios.patch(props.urls.update, values);

      if (hasFileData) {
        const fileResponse = await axios.patch(
          props.urls.update,
          fileData,
          config
        );
        merge(response, fileResponse);
      }

      if (response.data.redirect) {
        window.location = response.data.redirect;
      } else {
        updateEvent(response.data.event);
        actions.resetForm();
      }
    } catch (error) {
      actions.setErrors(error.response.data);
    }
    actions.setSubmitting(false);
  };

  const initialValues = pick(event, [
    "name",
    "url",
    "description",
    "location",
    "capacity",
    "budget",
    "start",
    "offers_text",
    "products",
    "audiences",
    "asks",
    "consent",
    "banner"
  ]);

  // TODO: centralize this. it's used in other event components
  const debouncedFetchProducts = debounce((inputValue, callback) => {
    axios
      .get(props.urls.product_type_list, {
        params: {
          name: inputValue
        }
      })
      .then(result => {
        callback(result.data);
      });
  }, 250);

  // TODO: centralize image handling. Too many different versions of this.
  const ImageField = ({ name, formik }) => {
    const value = formik.values[name];
    const [imagePreviewUrl, setImagePreviewUrl] = useState(value);
    const [imageUpdated, setImageUpdated] = useState(false);
    useEffect(() => {
      if (imageUpdated) {
        URL.revokeObjectURL(imagePreviewUrl);
        setImagePreviewUrl(value);
        setImageUpdated(false);
      }
    }, []);
    const onDropAccepted = file => {
      if (imageUpdated) {
        URL.revokeObjectURL(imagePreviewUrl);
      }
      const previewUrl = URL.createObjectURL(file);
      setImagePreviewUrl(previewUrl);
      setImageUpdated(true);
    };
    const onDropRejected = errors => {
      // TODO: figure out how to do upfront validation/error handling of imagefield
      // errors.map(file => {
      //   if (file.size > maxSize) {
      //     formik.setErrors({
      //       [name]: [`Image must not be larger than ${maxSize / 1000000}MB`]
      //     });
      //   }
      // });
    };
    return (
      <Dropzone
        className="tw-w-full tw-h-full tw-cursor-pointer"
        multiple={false}
        acceptClassName="accept"
        rejectClassName="reject"
        accept="image/jpeg, image/png"
        onDropAccepted={acceptedFiles => {
          const imageFile = acceptedFiles[0];
          formik.setFieldValue(name, imageFile);
          onDropAccepted(imageFile);
        }}
        onDropRejected={onDropRejected}
      >
        {imagePreviewUrl ? (
          <img
            className="tw-w-full tw-h-full tw-object-cover"
            src={imagePreviewUrl}
            alt=""
          />
        ) : (
          <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-w-full tw-h-full">
            <img
              className="tw-w-12 tw-h-12 tw-object-cover"
              src={plusIcon}
              alt="Add Picture"
            />
            <div className="tw-mt-4 tw-text-parsnip-charcoal tw-font-extrabold tw-text-xs tw-uppercase tw-text-parsnip-teal tw-tracking-parsnip-wide">
              Add an event image
            </div>
          </div>
        )}
      </Dropzone>
    );
  };

  return (
    <section>
      <div className="tw-max-w-xl tw-mx-auto">
        <h3>Update event</h3>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {formik => (
            <Form>
              <Field
                name="name"
                label="What is the name of your event?"
                component={BulmaTextField}
              />
              <Field
                name="url"
                label="Do you have a link to share? (Eventbrite or other?)"
                type="url"
                component={BulmaTextField}
                placeholder="https://myeventinfo.com"
              />
              <Field
                name="description"
                label="Provide a brief summary of your event"
                component={BulmaTextAreaField}
              />
              <div className="tw-mb-3">
                <h4 className="tw-font-bold tw-text-base tw-my-2">
                  Provide a header image for your event
                </h4>
                <div className="tw-h-64 tw-w-full tw-p-2 tw-border tw-border-gray-600 tw-rounded-lg">
                  <ImageField name="banner" formik={formik} />
                </div>
                <ErrorMessage name="banner" component={ErrorComponent} />
              </div>
              <BulmaFieldArray
                name="asks"
                label="What are you looking for?"
                options={props.match_types}
                helpText="Select as many as you'd like"
                form={formik}
              />
              <Field
                name="products"
                label="What products are you looking for?"
                helpText="Start typing to search"
                component={BulmaAsyncSelectField}
                multiple={true}
                fetchOptions={(input, callback) => {
                  if (!input) {
                    return Promise.resolve({ options: [] });
                  }
                  debouncedFetchProducts(input, callback);
                }}
              />
              <Field
                name="location"
                label="Where is the event?"
                component={BulmaTextField}
                placeholder="Anytown, CA"
              />
              <Field
                name="capacity"
                type="number"
                label="For how many people?"
                component={BulmaTextField}
                placeholder="0"
              />
              <Field
                name="budget"
                label="What is your budget?"
                component={BulmaCurrencyField}
              />
              <Field
                name="start"
                label="Start Date"
                component={BulmaDateField}
              />

              <BulmaFieldArray
                name="audiences"
                label="Who is your target audience?"
                options={props.audiences}
                helpText="Select all that apply"
                form={formik}
              />
              <Field
                name="offers_text"
                label="What exposure are you willing to provide in exchange for product? (Be as specific as possible.)"
                component={BulmaTextAreaField}
              />
              <Field
                name="consent"
                label="Can we share this event in our newsletter and social channels?"
                component={BulmaCheckboxField}
              />
              <button type="submit" className="tw-button cta tw-mt-8">
                Submit
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </section>
  );
};

Update.propTypes = {
  // TODO: build event proptypes shape
  event: PropTypes.shape({}),
  urls: PropTypes.shape({
    update: PropTypes.string.isRequired,
    product_type_list: PropTypes.string.isRequired
  }),
  audiences: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired
    })
  ).isRequired
};
export { Update };
