import React, { useState } from "react";
import { setPropTypes, withHandlers, compose } from "recompose";

import merge from "lodash/merge";

import Dropzone from "react-dropzone";
import axios from "js/components/common/axios";

import PropTypes from "prop-types";

import { ConnectForm } from "./Forms";
import BrandProfile from "./Brand";
import ServiceProfile from "./Service";
import InfluencerProfile from "./Influencer";
import EventProfile from "./Event";
import SpaceProfile from "./Space";

import { EditButton } from "./Cards";
import classNames from "classnames";
import normalizeUrl from "normalize-url";
import pick from "lodash/pick";
import { EditableCard, EditableInfoCard } from "./Cards";
import {
  EditableTextField,
  EditableTextArea,
  EditableYearField,
  EditableTagList,
  EditableImageField
} from "js/components/common/EditableField";
import { useApiObject } from "js/components/common/helpers";

import defaultLogo from "images/profile/plogo.png";
import certificateIcon from "images/profile/certificate.svg";
import laptopIcon from "images/profile/laptop.svg";
import lightningIcon from "images/profile/lightning.svg";
import locationIcon from "images/profile/location.svg";
import starIcon from "images/profile/star.svg";
import sunIcon from "images/profile/sun.svg";
import twitterGlyph from "images/profile/twitter-glyph.svg";
import instagramGlyph from "images/profile/instagram-glyph.svg";
import facebookGlyph from "images/profile/facebook-glyph.svg";

import plusIcon from "images/profile/plus.svg";

const withPropTypes = setPropTypes({
  // Permissions props
  permissions: PropTypes.shape({
    can_edit: PropTypes.bool.isRequired,
    can_connect: PropTypes.bool.isRequired,
    is_authenticated: PropTypes.bool.isRequired
  }),

  // API URLs
  urls: PropTypes.shape({
    update: PropTypes.string.isRequired,
    connect: PropTypes.string.isRequired
  }),

  // Tags
  match_types: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired
    })
  ),
  audiences: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired
    })
  ),
  certifications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired
    })
  ),
  product_certifications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired
    })
  ),
  influencer_topics: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired
    })
  )
});

// TODO: rename these so that initialProps and state have unique names?
// TODO: replace this with e.g. Hooks
const withFormState = withHandlers({
  handleConnectRequest: ({ urls, organization }) => values => {
    values["target"] = organization.id;
    return axios.post(urls.connect, values).then(response => {});
  }
});

const profiles = {
  brand: BrandProfile,
  service_provider: ServiceProfile,
  influencer: InfluencerProfile,
  event: EventProfile,
  space: SpaceProfile
};

const ProfileCore = props => {
  const [organization, updateOrganization] = useApiObject(props.organization);
  const [connectFormVisible, setConnectFormVisible] = useState(false);
  const isBrand = organization.type == "brand";
  const isEvent = organization.type == "event";
  const isInfluencer = organization.type == "influencer";
  const isSpace = organization.type == "space";
  const isService = organization.type == "service_provider";

  // TODO: generalize handling of API calls like this:
  // https://www.robinwieruch.de/react-hooks-fetch-data
  const handleSubmit = async values => {
    // Send JSON and File data in separate requests
    let fileData = new FormData();
    let hasFileData = false;
    const fileKeys = ["logo", "banner"];
    fileKeys.map(key => {
      if (values[key] instanceof File) {
        fileData.append(key, values[key]);
        hasFileData = true;
      }
      delete values[key];
    });
    const config = {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    };

    const response = await axios.patch(props.urls.update, values);
    if (hasFileData) {
      const fileResponse = await axios.patch(
        props.urls.update,
        fileData,
        config
      );
      merge(response, fileResponse);
    }

    updateOrganization(response.data.organization);

    return response;
  };
  const ImageEditor = ({ name, children }) => (
    <Dropzone
      multiple={false}
      accept="image/jpeg, image/png"
      className="tw-w-auto tw-h-auto tw-border-none tw-cursor-pointer"
      onDropAccepted={acceptedFiles => {
        const imageFile = acceptedFiles[0];
        handleSubmit({ [name]: imageFile });
      }}
    >
      {children}
    </Dropzone>
  );

  return (
    <>
      <div className="profile__header">
        {organization.banner ? (
          <div className="tw-relative tw-w-full tw-h-full">
            {props.permissions.can_edit && (
              <div className="profile__header-edit">
                <ImageEditor name="banner">
                  <EditButton />
                </ImageEditor>
              </div>
            )}
            <img
              className="tw-w-full tw-h-full tw-object-cover"
              src={organization.banner}
            />
          </div>
        ) : (
          <>
            {props.permissions.can_edit && (
              <div className="tw-hidden laptop-up:tw-flex tw-w-full tw-h-full tw-flex-col tw-justify-center tw-items-center">
                <div className="tw-font-semibold tw-text-2xl tw-text-parsnip-charcoal tw-my-6">
                  Looking a bit empty... Let's add a picture.
                </div>
                <ImageEditor name="banner">
                  <div className="profile__header-add">
                    <img
                      className="tw-w-12 tw-h-12 tw-bg-cover"
                      src={plusIcon}
                      alt="Add"
                    />
                    <div className="tw-mt-4">Add Picture</div>
                  </div>
                </ImageEditor>
              </div>
            )}
          </>
        )}
      </div>

      <div className="profile__wrapper">
        <div className="profile__layout">
          <div className="profile__sidebar">
            <EditableCard
              initialValues={pick(organization, [
                "logo",
                "name",
                "location",
                "website",
                "twitter",
                "instagram",
                "facebook"
              ])}
              handleSubmit={handleSubmit}
              canEdit={props.permissions.can_edit}
              render={({ editing, formik }) => (
                <div className="profile__overview">
                  <div className="profile__overview-headboard" />
                  <div className="profile__overview-logo">
                    <EditableImageField
                      editing={editing}
                      name="logo"
                      formik={formik}
                      defaultImage={defaultLogo}
                    />
                  </div>

                  <div className="tw-flex tw-justify-center tw-w-full tw-text-center tw-px-4 tw-py-2">
                    <EditableTextField
                      editing={editing}
                      name="name"
                      placeholder="Company Name"
                      formik={formik}
                      defaultRender={({ value }) => <h3>{value}</h3>}
                    />
                  </div>
                  <div className="profile__overview-body">
                    {(editing || formik.values["location"]) && (
                      <div className="tw-flex tw-justify-center tw-items-center tw-w-full tw-py-2">
                        <img
                          className="tw-w-6 tw-h-6 tw-mr-2"
                          src={locationIcon}
                          alt=""
                        />
                        <EditableTextField
                          editing={editing}
                          name="location"
                          placeholder="Location"
                          formik={formik}
                          defaultRender={({ value }) => (
                            <span className="tw-truncate">{value}</span>
                          )}
                        />
                      </div>
                    )}
                    {(editing || organization.website) && (
                      <div className="tw-flex tw-justify-center tw-items-center tw-w-full tw-py-2">
                        <img
                          className="tw-w-6 tw-h-6 tw-mr-2"
                          src={laptopIcon}
                          alt=""
                        />
                        <EditableTextField
                          type="url"
                          editing={editing}
                          name="website"
                          placeholder="Website"
                          formik={formik}
                          defaultRender={({ value }) => (
                            <a
                              className="tw-truncate tw-text-parsnip-black"
                              href={value}
                            >
                              {normalizeUrl(value, {
                                stripProtocol: true
                              })}
                            </a>
                          )}
                        />
                      </div>
                    )}
                    <div
                      className={classNames("profile__overview-social", {
                        editing: editing
                      })}
                    >
                      {editing ||
                      organization.instagram ||
                      organization.twitter ||
                      organization.facebook ? (
                        <>
                          {(editing || organization.instagram) && (
                            <div className="tw-flex tw-justify-center tw-items-center tw-py-2">
                              <img
                                className={classNames(
                                  "tw-w-6",
                                  "tw-h-6",
                                  "tw-mr-2",
                                  {
                                    "tw-hidden": !editing
                                  }
                                )}
                                src={instagramGlyph}
                                alt="Instagram"
                              />
                              <EditableTextField
                                editing={editing}
                                name="instagram"
                                prefix="@"
                                formik={formik}
                                defaultRender={({ value }) => (
                                  <a
                                    href={`https://instagram.com/${value}`}
                                    target="_blank"
                                    className="tw-px-4"
                                  >
                                    <img
                                      className="tw-w-5 tw-h-5"
                                      src={instagramGlyph}
                                      alt="Instagram"
                                    />
                                  </a>
                                )}
                              />
                            </div>
                          )}
                          {(editing || organization.twitter) && (
                            <div className="tw-flex tw-justify-center tw-items-center tw-py-2">
                              <img
                                className={classNames(
                                  "tw-w-6",
                                  "tw-h-6",
                                  "tw-mr-2",
                                  {
                                    "tw-hidden": !editing
                                  }
                                )}
                                src={twitterGlyph}
                                alt="Twitter"
                              />
                              <EditableTextField
                                editing={editing}
                                name="twitter"
                                prefix="@"
                                formik={formik}
                                defaultRender={({ value }) => (
                                  <a
                                    href={`https://twitter.com/${value}`}
                                    target="_blank"
                                    className="tw-px-4"
                                  >
                                    <img
                                      className="tw-w-5 tw-h-5"
                                      src={twitterGlyph}
                                      alt="Twitter"
                                    />
                                  </a>
                                )}
                              />
                            </div>
                          )}
                          {(editing || organization.facebook) && (
                            <div className="tw-flex tw-justify-center tw-items-center tw-py-2">
                              <img
                                className={classNames(
                                  "tw-w-6",
                                  "tw-h-6",
                                  "tw-mr-2",
                                  {
                                    "tw-hidden": !editing
                                  }
                                )}
                                src={facebookGlyph}
                                alt="Facebook"
                              />
                              <EditableTextField
                                editing={editing}
                                name="facebook"
                                prefix="fb.me/"
                                formik={formik}
                                defaultRender={({ value }) => (
                                  <a
                                    href={`https://facebook.com/${value}`}
                                    target="_blank"
                                    className="tw-px-4"
                                  >
                                    <img
                                      className="tw-w-5 tw-h-5"
                                      src={facebookGlyph}
                                      alt="Facebook"
                                    />
                                  </a>
                                )}
                              />
                            </div>
                          )}
                        </>
                      ) : (
                        <h2 className="tw-text-gray-500 tw-text-center">
                          {props.permissions.can_edit ? (
                            <span>Share your socials</span>
                          ) : (
                            <span />
                          )}
                        </h2>
                      )}
                    </div>
                  </div>
                </div>
              )}
            />
            {props.permissions.is_authenticated && (
              <EditableInfoCard
                title="Quick Facts"
                icon={lightningIcon}
                initialValues={pick(organization, [
                  "founded",
                  "geography_growth",
                  "support",
                  "dealbreakers"
                ])}
                handleSubmit={handleSubmit}
                canEdit={props.permissions.can_edit}
                render={({ editing, formik }) => {
                  return (
                    <>
                      {editing ||
                      organization.founded ||
                      organization.geography_growth ||
                      organization.support ||
                      organization.dealbreakers ? (
                        <>
                          {(editing || organization.founded) && (
                            <div className="profile__infocard-section laptop-up:tw-border-t tw-border-parsnip-charcoal tw--mx-4 tw-pt-4 tw-px-4">
                              <h4 className="tw-m-0 tw-mb-2 tw-font-semibold">
                                Founded In
                              </h4>
                              <EditableYearField
                                editing={editing}
                                name="founded"
                                formik={formik}
                              />
                            </div>
                          )}
                          {!props.consumer &&
                            isBrand &&
                            (editing || organization.geography_growth) && (
                              <div className="profile__infocard-section laptop-up:tw-border-t tw-border-parsnip-charcoal tw--mx-4 tw-pt-4 tw-px-4">
                                <h4 className="tw-mt-0 tw-mb-2 tw-font-semibold">
                                  Would Like to Expand
                                </h4>
                                <EditableTextArea
                                  editing={editing}
                                  name="geography_growth"
                                  placeholder="Indicate which regions"
                                  formik={formik}
                                />
                              </div>
                            )}
                          {!props.consumer &&
                            (isBrand || isInfluencer || isSpace || isEvent) &&
                            (editing || organization.support) && (
                              <div className="profile__infocard-section laptop-up:tw-border-t tw-border-parsnip-charcoal tw--mx-4 tw-pt-4 tw-px-4">
                                <h4 className="tw-mt-0 tw-mb-2 tw-font-semibold">
                                  We Support
                                </h4>
                                <EditableTextArea
                                  editing={editing}
                                  name="support"
                                  placeholder="What is your organization passionate about?"
                                  formik={formik}
                                />
                              </div>
                            )}
                          {(editing ||
                            (organization.dealbreakers && !props.consumer)) && (
                            <div className="profile__infocard-section laptop-up:tw-border-t tw-border-parsnip-charcoal tw--mx-4 tw-pt-4 tw-px-4">
                              <h4 className="tw-mt-0 tw-mb-2 tw-font-semibold">
                                Dealbreakers
                              </h4>
                              <EditableTextArea
                                editing={editing}
                                name="dealbreakers"
                                formik={formik}
                                rows={2}
                              />
                            </div>
                          )}
                        </>
                      ) : (
                        <h2 className="tw-text-gray-500 tw-text-center">
                          {props.permissions.can_edit ? (
                            <span>Share your deets</span>
                          ) : (
                            <span>Nothing here :(</span>
                          )}
                        </h2>
                      )}
                    </>
                  );
                }}
              />
            )}
            {props.permissions.can_connect && (
              <button
                onClick={() => setConnectFormVisible(true)}
                className="tw-hidden laptop-up:tw-flex tw-w-full tw-button tw-justify-center tw-bg-parsnip-mustard tw-text-parsnip-charcoal tw-border-none tw-px-12 tw-py-4"
              >
                Connect
              </button>
            )}
          </div>
          <div className="profile__main">
            {(isBrand || isService || isSpace) && (
              <EditableInfoCard
                title="About Us"
                icon={sunIcon}
                initialValues={pick(organization, [
                  "description",
                  "certifications"
                ])}
                handleSubmit={handleSubmit}
                canEdit={props.permissions.can_edit}
                render={({ editing, formik }) => (
                  <>
                    <div className="profile__infocard-section">
                      {editing && (
                        <h4 className="tw-mt-0 profile__infocard-sectionheader">
                          Share your story - what should potential brand
                          partners know about you?
                        </h4>
                      )}
                      <EditableTextArea
                        editing={editing}
                        name="description"
                        formik={formik}
                        rows={8}
                        canEdit={props.permissions.can_edit}
                        emptyMessage="Tell others what you’re all about"
                      />
                    </div>
                    {props.permissions.is_authenticated && (
                      <div className="profile__infocard-section">
                        <h4 className="profile__infocard-sectionheader">
                          Organizational Labels and Certifications
                        </h4>
                        {editing && (
                          <h4 className="profile__infocard-helptext">
                            Select all that apply
                          </h4>
                        )}
                        <EditableTagList
                          editing={editing}
                          name="certifications"
                          formik={formik}
                          options={props.certifications}
                          canEdit={props.permissions.can_edit}
                          emptyMessage="Select your company labels and certifications"
                        />
                      </div>
                    )}
                  </>
                )}
              />
            )}
            {isEvent && (
              <EditableInfoCard
                title="About Us"
                icon={sunIcon}
                initialValues={pick(organization, ["description", "audiences"])}
                handleSubmit={handleSubmit}
                canEdit={props.permissions.can_edit}
                render={({ editing, formik }) => (
                  <>
                    <div className="profile__infocard-section">
                      {editing && (
                        <h4 className="tw-mt-0 profile__infocard-sectionheader">
                          Share your story - what should potential brand
                          partners know about you?
                        </h4>
                      )}
                      <EditableTextArea
                        editing={editing}
                        name="description"
                        formik={formik}
                        rows={8}
                        canEdit={props.permissions.can_edit}
                        emptyMessage="Tell others what you’re all about"
                      />
                    </div>
                    {props.permissions.is_authenticated && (
                      <div className="profile__infocard-section">
                        <h4 className="profile__infocard-sectionheader">
                          Audience
                        </h4>
                        {editing && (
                          <h4 className="profile__infocard-helptext">
                            Select all that apply
                          </h4>
                        )}
                        <EditableTagList
                          editing={editing}
                          name="audiences"
                          formik={formik}
                          options={props.audiences}
                          canEdit={props.permissions.can_edit}
                          emptyMessage="Select your target audience"
                        />
                      </div>
                    )}
                  </>
                )}
              />
            )}
            {isInfluencer && (
              <EditableInfoCard
                title="About Me"
                icon={sunIcon}
                initialValues={pick(organization, ["description", "offers"])}
                handleSubmit={handleSubmit}
                canEdit={props.permissions.can_edit}
                render={({ editing, formik }) => (
                  <>
                    <div className="profile__infocard-section">
                      {editing && (
                        <h4 className="tw-mt-0 profile__infocard-sectionheader">
                          Share your story - what should potential brand
                          partners know about you?
                        </h4>
                      )}
                      <EditableTextArea
                        editing={editing}
                        name="description"
                        formik={formik}
                        rows={8}
                        canEdit={props.permissions.can_edit}
                        emptyMessage="Tell others what you’re all about"
                      />
                    </div>
                    {props.permissions.is_authenticated && (
                      <div className="profile__infocard-section">
                        <h4 className="profile__infocard-sectionheader">
                          Content Types I Create
                        </h4>
                        {editing && (
                          <h4 className="profile__infocard-helptext">
                            Select all that apply
                          </h4>
                        )}
                        <EditableTagList
                          editing={editing}
                          name="offers"
                          formik={formik}
                          options={props.match_types}
                          canEdit={props.permissions.can_edit}
                          emptyMessage="Select the types of content you create"
                        />
                      </div>
                    )}
                  </>
                )}
              />
            )}
            {props.permissions.is_authenticated && !props.consumer && (
              <EditableInfoCard
                title="Collaboration"
                icon={starIcon}
                initialValues={pick(organization, ["asks_text", "asks"])}
                handleSubmit={handleSubmit}
                canEdit={props.permissions.can_edit}
                render={({ editing, formik }) => (
                  <>
                    <div className="profile__infocard-section">
                      {editing && (
                        <h4 className="tw-mt-0 profile__infocard-sectionheader">
                          What are you looking for in your partners?
                        </h4>
                      )}
                      <EditableTextArea
                        editing={editing}
                        name="asks_text"
                        formik={formik}
                        canEdit={props.permissions.can_edit}
                        emptyMessage="Let others know what you’re interested in"
                      />
                    </div>
                    <div className="profile__infocard-section">
                      <h4 className="profile__infocard-sectionheader">
                        Collaboration Interests
                      </h4>
                      {editing && (
                        <h4 className="profile__infocard-helptext">
                          Select all that apply
                        </h4>
                      )}
                      <EditableTagList
                        editing={editing}
                        name="asks"
                        formik={formik}
                        options={props.match_types}
                        canEdit={props.permissions.can_edit}
                        emptyMessage="Select a few partnership types"
                      />
                    </div>
                  </>
                )}
              />
            )}

            {/* Org offers block */}
            {props.permissions.is_authenticated &&
              (!props.consumer || isBrand) && (
                <EditableInfoCard
                  title={
                    {
                      brand: "Our Products",
                      influencer: "Reach Out to Me For",
                      event: "What We Can Offer",
                      service_provider: "Reach Out to Us For",
                      space: "Reach Out to Us For"
                    }[organization.type]
                  }
                  icon={certificateIcon}
                  initialValues={pick(
                    organization,
                    isBrand
                      ? ["product_text", "product_certifications"]
                      : isInfluencer
                      ? ["offers_text", "topics"]
                      : ["offers_text", "offers"]
                  )}
                  handleSubmit={handleSubmit}
                  canEdit={props.permissions.can_edit}
                  render={({ editing, formik }) => (
                    <>
                      <div className="profile__infocard-section">
                        {editing && (
                          <h4 className="tw-mt-0 profile__infocard-sectionheader">
                            Share a little something about what you offer
                          </h4>
                        )}
                        {isBrand && (
                          <EditableTextField
                            editing={editing}
                            name="product_text"
                            formik={formik}
                            canEdit={props.permissions.can_edit}
                            emptyMessage="Briefly describe your products"
                          />
                        )}
                        {(isEvent || isSpace || isService || isInfluencer) && (
                          <EditableTextField
                            editing={editing}
                            name="offers_text"
                            formik={formik}
                            canEdit={props.permissions.can_edit}
                            emptyMessage="What can you offer to a partnership?"
                          />
                        )}
                      </div>
                      {isBrand && (
                        <div className="profile__infocard-section">
                          <h4 className="profile__infocard-sectionheader">
                            Product Labels and Certifications
                          </h4>
                          {editing && (
                            <h4 className="profile__infocard-helptext">
                              Select all that apply
                            </h4>
                          )}
                          <EditableTagList
                            editing={editing}
                            name="product_certifications"
                            formik={formik}
                            options={props.product_certifications}
                            canEdit={props.permissions.can_edit}
                            emptyMessage="Select your product certifications"
                          />
                        </div>
                      )}
                      {(isEvent || isSpace || isService) && (
                        <div className="profile__infocard-section">
                          <h4 className="profile__infocard-sectionheader">
                            We Can Provide
                          </h4>
                          {editing && (
                            <h4 className="profile__infocard-helptext">
                              Select all that apply
                            </h4>
                          )}
                          <EditableTagList
                            editing={editing}
                            name="offers"
                            formik={formik}
                            options={props.match_types}
                            canEdit={props.permissions.can_edit}
                            emptyMessage="What can you help with?"
                          />
                        </div>
                      )}
                      {isInfluencer && (
                        <div className="profile__infocard-section">
                          <h4 className="profile__infocard-sectionheader">
                            My Expertise
                          </h4>
                          {editing && (
                            <div className="profile__infocard-helptext">
                              Select all that apply
                            </div>
                          )}
                          <EditableTagList
                            editing={editing}
                            name="topics"
                            formik={formik}
                            options={props.influencer_topics}
                            canEdit={props.permissions.can_edit}
                            emptyMessage="What topics are in your wheelhouse?"
                          />
                        </div>
                      )}
                    </>
                  )}
                />
              )}
          </div>
        </div>
      </div>

      <ConnectForm
        isOpen={connectFormVisible}
        handleSubmit={props.handleConnectRequest}
        handleClose={() => {
          setConnectFormVisible(false);
        }}
        organization={organization}
        match_types={props.match_types}
      />
    </>
  );
};

const Profile = compose(withPropTypes, withFormState)(ProfileCore);

export { Profile };
