import React from "react";
import ReactModal from "react-modal";
import classNames from "classnames";
import Tippy from "@tippy.js/react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import tw from "twin.macro";
import defaultLogo from "images/profile/plogo.png";

const WhiteCard = styled.div`
  ${tw`bg-white rounded-lg p-8 overflow-hidden shadow-lg`}
`;

const Logo = styled("div")`
  background: url(${props => (props.src ? props.src : defaultLogo)}) no-repeat
    50% 50% / cover;
  width: ${props => (props.size ? props.size : "8rem")};
  height: ${props => (props.size ? props.size : "8rem")};
  ${tw`rounded-full shadow-inner`}
`;

const SocialIcon = styled.div`
  background-image: url(${props => props.src});
  ${tw`w-6 h-6 bg-contain`}
`;

const Label = styled.div`
  ${tw`font-semibold text-parsnip-charcoal`}
`;

const baseTagStyle = css`
  ${tw`flex justify-center items-center rounded-full whitespace-no-wrap`}
  ${tw`font-extrabold text-xs uppercase`}
  ${tw`px-4`}
  ${tw`border`}

  &>* {
    ${tw`truncate`}
  }
`;
const baseTagColors = css`
  ${tw`bg-gray-300 text-parsnip-charcoal border-gray-300`}

  &.tag--editable {
    ${tw`bg-white text-parsnip-teal border-parsnip-teal`}

    &.checked {
      ${tw`bg-parsnip-teal text-white`}
    }
  }
`;

const yellowTagColors = css`
  ${tw`bg-white text-parsnip-teal border-parsnip-mustard`}
`;

const whiteTagColors = css`
  ${tw`bg-white text-parsnip-charcoal border-parsnip-teal`}
`;

const StyledTag = styled.label`
  width: 100%;
  max-width: 15rem;
  min-width: 12rem;
  height: 2rem;
  letter-spacing: 1.5px;
  ${baseTagStyle}
  ${({ theme }) => {
    switch (theme) {
      case "yellow":
        return yellowTagColors;
      case "white":
        return whiteTagColors;
      default:
        return baseTagColors;
    }
  }}
`;

const TagList = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
  justify-content: left;
  justify-items: left;
`;

const Tag = ({ label, theme, editable, checked, children }) => (
  <Tippy content={label} delay={[500, 0]}>
    <StyledTag
      theme={theme}
      className={classNames({ checked: checked, "tag--editable": editable })}
    >
      {children ? children : label}
    </StyledTag>
  </Tippy>
);

const ClickableTag = ({ id, name, label, checked, onChange, multiple }) => (
  // Adapted from https://medium.com/@colebemis/building-a-checkbox-component-with-react-and-styled-components-8d3aa1d826dd
  <Tag label={label} editable={true} checked={checked}>
    <input
      type={multiple ? "checkbox" : "radio"}
      name={name}
      value={id}
      checked={checked}
      onChange={onChange}
      className="hideVisually"
    />
    <span>{label}</span>
  </Tag>
);

const ErrorComponent = ({ children }) => (
  <span className="help tw-font-normal tw-text-red-700 tw-text-left">
    {children}
  </span>
);
const HelpText = ({ text }) => (
  <>
    {text && (
      <div className="help tw-font-normal tw-mb-4 tw-text-left">{text}</div>
    )}
  </>
);

// TODO: put this back in once we can guarantee that the #reactapp element will always exist
// (e.g. once the entire app is React)
// ReactModal.setAppElement("#reactapp");
const ModalWrapper = ({ isOpen, handleCloseModal, className, children }) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={handleCloseModal}
      portalClassName={className}
      className="Modal__Content"
      overlayClassName="Modal__Overlay"
      // TODO: Remove the following once we can guarantee that the #reactapp element will always exist
      ariaHideApp={false}
    >
      {children}
    </ReactModal>
  );
};
const Modal = styled(ModalWrapper)`

  .Modal__Overlay {
    /* TODO: use a plugin or function to generate this color instead of hardcoding it */
    background-color: rgba(247,250,252,0.85);
    ${tw`fixed inset-0 px-4 pb-6`}
    ${tw`flex items-end justify-center`}
    ${tw`tablet-up:p-0 tablet-up:items-center`}
  }
  .Modal__Content {
    ${tw`bg-white rounded-lg`}
    ${tw`w-full p-6`}
    ${tw`overflow-hidden`}
    ${tw`shadow-xl outline-none`}
    ${tw`tablet-up:max-w-xl`}
  }
`;

export {
  Tag,
  ClickableTag,
  TagList,
  ErrorComponent,
  HelpText,
  WhiteCard,
  Logo,
  SocialIcon,
  Label,
  Modal
};
