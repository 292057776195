import React from "react";
import { useForm, FormContext, useFormContext } from "react-hook-form";
import {
  createStore,
  useStateMachine,
  StateMachineProvider
} from "little-state-machine";
import * as Yup from "yup";
import {
  TextField,
  TextAreaField,
  YearField,
  SelectField,
  AsyncSelectField,
  CurrencyField,
  CheckboxField,
  DateField,
  TagArrayField
} from "js/components/common/forms/fields";
import { Page, Wizard } from "js/components/common/forms/Wizard";
import Registration from "js/components/Organizations/Registration/NewRegistration";

////////////////////////////////////////////
const tags = [
  { id: "1", name: "First Option", slug: "first-option" },
  { id: "2", name: "Second Option", slug: "second-option" },
  { id: "3", name: "Third Option", slug: "third-option" },
  { id: "4", name: "Fourth Option", slug: "fourth-option" },
  { id: "5", name: "Fifth Option", slug: "fifth-option" }
];

createStore({
  experimentalData: {}
});
const updateDataAction = (state, payload) => {
  return {
    ...state,
    experimentalData: {
      ...state.experimentalData,
      ...payload
    }
  };
};
const defaultValues = {
  textfield: "",
  urlfield: "",
  textareafield: "",
  selectfield: [],
  asyncselectfield: [],
  currencyfield: "",
  yearfield: "",
  tagarrayfieldsingle: tags[2],
  tagarrayfieldmulti: [tags[2], tags[3]],
  datefield: null
};

const FieldTest = () => {
  const { state, actions } = useStateMachine({ updateDataAction });
  const methods = useForm({
    defaultValues: { ...defaultValues, ...state.experimentalData }
  });
  const onSubmit = data => {
    actions.updateDataAction(data);
    console.dir("Submitted data:");
    console.dir(data);
  };
  return (
    <FormContext {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <TextField
          name="textfield"
          type="text"
          label="TextField"
          helpText="Some helptext"
          placeholder="Placeholder"
          prefix="@"
          autofocus={true}
        />
        <TextField name="urlfield" type="url" label="UrlField" />
        <TextAreaField name="textareafield" label="TextAreaField" rows={4} />
        <SelectField
          name="selectfield"
          label="SelectField"
          options={tags}
          multiple={true}
        />
        <AsyncSelectField
          name="asyncselectfield"
          label="AsyncSelectField"
          multiple={true}
          helpText="Start typing to search"
          fetchOptions={(input, callback) => {
            if (!input) {
              return Promise.resolve({ options: [] });
            }
            Promise.resolve().then(() => callback(tags));
          }}
        />
        <CurrencyField name="currencyfield" label="CurrencyField" />
        <YearField name="yearfield" label="YearField" />
        <CheckboxField name="checkboxfield" label="CheckboxField" />
        <DateField name="datefield" label="DateField" />
        <TagArrayField
          name="tagarrayfieldmulti"
          label="TagArrayField - multi"
          options={tags}
        />
        <TagArrayField
          name="tagarrayfieldsingle"
          label="TagArrayField - single"
          options={tags}
          multiple={false}
        />
        <button type="submit" className="tw-button">
          Submit
        </button>
      </form>
    </FormContext>
  );
};
////////////////////////////////////////////

const Page1 = () => {
  const ValidationSchema = Yup.object({
    textfield: Yup.string().required()
  });
  return (
    <Page
      defaultValues={{ textfield: "default text" }}
      validationSchema={ValidationSchema}
      getNextPage={values => {
        return Page2;
      }}
    >
      <TextField name="textfield" type="text" label="Text Field" />
    </Page>
  );
};
const Page2 = () => (
  <Page
    defaultValues={{ tagarrayfield: defaultValues.tagarrayfield }}
    getNextPage={values => {
      return Page3;
    }}
  >
    <TagArrayField
      name="tagarrayfield"
      label="Tag Array Field"
      options={tags}
    />
  </Page>
);
const Page3 = () => {
  const ValidationSchema = Yup.object({
    urlfield: Yup.string()
      .url()
      .required()
  });
  return (
    <Page
      defaultValues={{ urlfield: "" }}
      validationSchema={ValidationSchema}
      getNextPage={values => {}}
    >
      <TextField name="urlfield" type="url" label="Url Field" />
    </Page>
  );
};
const WizardTest = props => (
  <Wizard
    initialPage={Page1}
    onSubmit={() => {}}
    submissionConfig={{}}
    {...props}
  >
    <Page1 />
    <Page2 />
    <Page3 />
  </Wizard>
);

////////////////////////////////////////////

////////////////////////////////////////////
const org_types = [
  { id: "1", name: "First Option", slug: "first-option" },
  { id: "2", name: "Second Option", slug: "second-option" },
  { id: "3", name: "Third Option", slug: "third-option" },
  { id: "4", name: "Fourth Option", slug: "fourth-option" },
  { id: "5", name: "Fifth Option", slug: "fifth-option" }
];
const Forms = () => (
  <>
    <StateMachineProvider>
      <div className="tw-max-w-2xl tw-mx-auto">
        <h1>Field Test</h1>
        <FieldTest />
      </div>
      <div>
        <h1>Wizard Test</h1>
        <WizardTest />
      </div>
    </StateMachineProvider>
  </>
);

export default Forms;
