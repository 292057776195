import React from "react";
import { useFormContext, Controller, ErrorMessage } from "react-hook-form";
import isUndefined from "lodash/isUndefined";

import {
  HelpText,
  ErrorComponent,
  ClickableTag,
  TagList
} from "js/components/common/Widgets";

import { getUniqueId } from "js/components/common/helpers";

const TagArray = ({ name, options, value, onChange, multiple }) => {
  if (!isUndefined(value) && !Array.isArray(value)) {
    value = [value];
  }
  return (
    <TagList>
      {[...options]
        .sort((first, second) =>
          first.name.toUpperCase() < second.name.toUpperCase() ? -1 : 1
        )
        .map((option, index) => {
          let checked = false;
          // Have to use findIndex instead of find since find returns the found value,
          // and if that value is falsy then the logic check will also be false.
          value && value.findIndex(el => el === option.slug) !== -1
            ? (checked = true)
            : (checked = false);
          return (
            <ClickableTag
              key={index}
              name={name}
              id={option.id}
              label={option.name}
              checked={checked}
              onChange={e => {
                // Return the array of selected items if multiple
                // or the single item if not multiple
                let newValue = null;
                if (multiple) {
                  newValue = value ? [...value] : [];
                  if (e.target.checked) {
                    newValue.push(option.slug);
                  } else {
                    const idx = value.findIndex(el => el === option.slug);
                    newValue.splice(idx, 1);
                  }
                  onChange(newValue);
                } else {
                  newValue = option.slug;
                }
                onChange(newValue);
              }}
              multiple={multiple}
            />
          );
        })}
    </TagList>
  );
};

const TagArrayField = ({ name, label, helpText, options, multiple = true }) => {
  const { errors } = useFormContext();
  const inputId = `${name}_${getUniqueId()}`;
  return (
    <div className="field">
      <label htmlFor={inputId} className="label">
        {label}
      </label>
      <HelpText text={helpText} />
      <ErrorMessage name={name} errors={errors} as={ErrorComponent} />
      <Controller
        id={inputId}
        name={name}
        onChange={([selected]) => {
          return { value: selected };
        }}
        options={options}
        multiple={multiple}
        as={<TagArray />}
      />
    </div>
  );
};

// TODO: Is there a cleaner way to do this?
const BooleanTagField = ({ name, label, helpText }) => {
  const { errors } = useFormContext();

  const options = [
    { id: 0, name: "Yes", slug: true },
    { id: 1, name: "No", slug: false }
  ];
  const inputId = `${name}_${getUniqueId()}`;
  return (
    <div className="field">
      <label htmlFor={inputId} className="label">
        {label}
      </label>
      <HelpText text={helpText} />
      <ErrorMessage name={name} errors={errors} as={ErrorComponent} />
      <Controller
        id={inputId}
        name={name}
        onChange={([selected]) => {
          return selected;
        }}
        options={options}
        multiple={false}
        valueName="value"
        as={<TagArray />}
      />
    </div>
  );
};

export { TagArrayField, BooleanTagField };
