import React, { useState, useEffect } from "react";
import {
  useQueryParams,
  NumberParam,
  StringParam,
  JsonParam
} from "use-query-params";
import querystring from "query-string";
import isEmpty from "lodash/isEmpty";
import xorBy from "lodash/xorBy";
import Filter from "./Filter";
import Menu from "./Menu";
import Results from "./Results";

// Get the filter category slugs that are valid for the given orgType
const getValidFilterSlugs = (filters, orgType) => {
  const filterSlugs =
    orgType === "all"
      ? filters.map(f => f.slug)
      : filters
          .filter(f => f.valid_for.some(val => val === orgType))
          .map(f => f.slug);
  return filterSlugs;
};

// Combine the valid filter slugs and the currently active filters into
// a json object for use in the querystring and API call
const encodeFilters = (validFilterSlugs, activeFilters) => {
  const queryFilters = {};
  activeFilters
    .filter(f => validFilterSlugs.includes(f.categorySlug))
    .map(f => {
      queryFilters[f.categorySlug]
        ? queryFilters[f.categorySlug].push(f.slug)
        : (queryFilters[f.categorySlug] = [f.slug]);
    });
  return queryFilters;
};

// Used to populate the initial activeFilters from the querystring
const decodeFilters = (queryFilters, validFilterSlugs, allFilters) => {
  const activeFilters = [];
  Object.keys(queryFilters).map(categorySlug => {
    if (validFilterSlugs.includes(categorySlug)) {
      queryFilters[categorySlug].map(slug => {
        const option = allFilters
          .find(f => f.slug === categorySlug)
          .options.find(o => o.slug === slug);
        activeFilters.push({
          id: option.id,
          name: option.name,
          slug: option.slug,
          categorySlug: categorySlug
        });
      });
    }
  });
  return activeFilters;
};

const FilterPage = props => {
  // Get some state from query params
  const [query, setQuery] = useQueryParams({
    search: StringParam,
    type: StringParam,
    page: NumberParam,
    filters: JsonParam
  });

  // State managed in the querystring
  // These are the only parameters that should cause a search to be run
  const {
    search: searchTerm = "",
    type: orgType = props.organization_types[0].slug,
    page: pageNum = 1,
    filters: queryFilters = {}
  } = query;

  // Get other state from props
  const initialState = {
    results: props.results || [],
    numPages: Math.ceil(props.count / props.page_size) || 1,
    totalResults: props.count || 0
  };
  const [state, setState] = useState(initialState);
  const [shouldSearch, triggerSearch] = useState(false);

  // Initial state is a function, so it will not be called after initial render
  const [validFilterSlugs, setValidFilterSlugs] = useState(() =>
    getValidFilterSlugs(props.filters, orgType)
  );

  // activeFilters is an array of objects of shape [{id, slug, categorySlug}]
  // Initial state is a function, so it will not be called after initial render
  const [activeFilters, setActiveFilters] = useState(() =>
    decodeFilters(queryFilters, validFilterSlugs, props.filters)
  );
  const [showMobileFilterMenu, toggleMobileFilterMenu] = useState(false);

  // Set the valid filters for the selected orgType
  useEffect(() => {
    setValidFilterSlugs(getValidFilterSlugs(props.filters, orgType));
  }, [orgType]);

  // Update the active filters based on the valid filters (which depend on the selected orgType)
  // useEffect(() => {
  //   const active = {};
  //   validFilters.forEach(filter => {
  //     const arr = [];
  //     filter.options.forEach(option => {
  //       if (option.active === true) {
  //         arr.push(option.slug);
  //       }
  //     });
  //     arr.length > 0 && (active[filter.slug] = arr);
  //   });

  //   // Update the queryparams with the correct valid filters
  //   setQuery({ filters: isEmpty(active) ? null : active }, "replaceIn");
  // }, [validFilters]);

  // Update the final queryparam filters based on the intersection of the valid filters and the active (selected) filters
  // queryFilters should look like:
  // {
  //   categorySlug1: [optionSlug1, optionSlug2],
  //   categorySlug2: [optionSlug1, optionSlug2]
  // }
  useEffect(() => {
    const queryFilters = encodeFilters(validFilterSlugs, activeFilters);
    setQuery(
      { filters: isEmpty(queryFilters) ? null : queryFilters, page: null },
      "replaceIn"
    );
    triggerSearch(Date.now());
  }, [validFilterSlugs, activeFilters]);

  useEffect(
    () => {
      const search = () => {
        const searchUrl = props.urls.search_api;
        const filterParam = Object.keys(queryFilters).length
          ? `&filters=${encodeURIComponent(JSON.stringify(queryFilters))}`
          : "";
        const searchParam = searchTerm ? `search=${searchTerm}` : "";
        const typeParam =
          orgType && orgType !== "all" ? `&type=${orgType}` : "";
        const pageParam = pageNum > 1 ? `&page=${pageNum}` : "";
        setState({
          ...state,
          loading: true,
          error: false
        });
        fetch(
          `${searchUrl}?${searchParam}${typeParam}${filterParam}${pageParam}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json"
            }
          }
        ).then(res => {
          res
            .json()
            .then(res => {
              // TODO: why is this called twice inside search?
              triggerSearch(false);
              setState({
                ...state,
                loading: false,
                error: false,
                results: res.results,
                numPages: Math.ceil(res.count / res.page_size),
                totalResults: res.count
              });
            })
            .catch(error =>
              setState({
                ...state,
                loading: false,
                error,
                results: []
              })
            );
        });
        triggerSearch(false);
      };

      // setSyncedFilters(syncFiltersAndOrg(orgType, props.filters));
      shouldSearch && search();
    },

    // Search will be triggered when any of these variables change
    [searchTerm, orgType, pageNum, queryFilters]
  );

  const setOrganizationType = val => {
    const newOrgType = val !== "all" ? val : "";
    // TODO: once i figure out the issue with the pagination not getting updated, set "page" to null here
    setQuery({ type: newOrgType, page: null }, "replaceIn");
    triggerSearch(Date.now());
  };

  const toggleFilter = e => {
    const data = e.target.dataset;
    const toggledFilter = {
      id: data.id,
      name: data.name,
      slug: data.slug,
      categorySlug: data.type
    };
    // Lodash xor toggles items in an array
    const newFilters = xorBy(activeFilters, [toggledFilter], "id");
    setActiveFilters(newFilters);

    // const slugs = activeFilters[data.type] || [];
    // const newSlugs = xor(slugs, [data.slug]);
    // activeFilters[data.type] = newSlugs;
    // setActiveFilters(activeFilters);

    // const active = validFilters.map(filter => {
    //   if (filter.slug === e.target.dataset.type) {
    //     filter.options.map(option => {
    //       if (option.id === e.target.dataset.id) {
    //         option.active = !option.active;
    //       }
    //     });
    //     return filter;
    //   } else {
    //     return filter;
    //   }
    // });
    // setQuery({ filters: isEmpty(active) ? null : active }, "replaceIn");
    // triggerSearch(Date.now());
  };

  const clearTerm = () => {
    // Since we want to update the search term in the header as well, we have to perform a page reload
    // But we also need to keep all of the existing search params
    const parsed = querystring.parse(location.search);
    if (isEmpty(parsed.search)) {
      return;
    }
    delete parsed.search;
    const newQuerystring = querystring.stringify(parsed);
    const newUrl = `${props.urls.search}?${newQuerystring}`;
    window.location.replace(newUrl);
  };

  const selectPage = e => {
    setQuery({ page: e.selected + 1 }, "replaceIn");
    triggerSearch(Date.now());
    window.scrollTo(0, 0);
  };

  const validFilters = props.filters.filter(f =>
    validFilterSlugs.includes(f.slug)
  );

  return (
    <div className="tw-flex-1 tw-bg-gray-100 tw-flex tw-flex-col">
      <Menu
        setOrganization={setOrganizationType}
        types={props.organization_types}
        active={orgType}
      />

      <div className="tw-flex-1 tw-max-w-6xl tw-flex tw-container tw-px-6">
        <div className="tw-relative tw-flex-1 tw-flex tw-justify-start tw-pt-8">
          <Filter
            filters={validFilters}
            activeFilters={activeFilters}
            update={toggleFilter}
            showMobileMenu={showMobileFilterMenu}
            toggleMobileFilterMenu={() =>
              toggleMobileFilterMenu(!showMobileFilterMenu)
            }
          />
          <Results
            {...state}
            numPages={state.numPages}
            totalResults={state.totalResults}
            pageNum={pageNum}
            activeFilters={activeFilters}
            update={toggleFilter}
            clearTerm={clearTerm}
            onPageChange={selectPage}
            term={searchTerm}
            toggleMobileFilterMenu={() =>
              toggleMobileFilterMenu(!showMobileFilterMenu)
            }
          />
        </div>
      </div>
    </div>
  );
};

export default props => <FilterPage {...props} />;
