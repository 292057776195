import React from "react";
import dateFnsFormat from "date-fns/format";
import parseISO from "date-fns/parseISO";
import PropTypes from "prop-types";

const Detail = ({ event, ...props }) => {
  return (
    <div className="tw-bg-gray-100">
      <div className="tw-max-w-xl tw-mx-auto tw-px-4 tw-py-12">
        <div className="tw-bg-white tw-rounded-lg tw-overflow-hidden tw-shadow-lg">
          <div className="event__header">
            {event.banner && (
              <img
                className="tw-w-full tw-h-full tw-object-cover"
                src={event.banner}
              />
            )}
          </div>
          <div className="tw-p-8">
            <div className="tw-mb-8">
              <h3 className="tw-mb-0">{event.name}</h3>
              <a
                className="tw-text-parsnip-charcoal tw-text-sm"
                href={event.url}
                target="_blank"
              >
                {event.url}
              </a>
            </div>

            <h4 className="tw-mb-0 tw-font-semibold">About this event</h4>
            <div>{event.description}</div>

            <h4 className="tw-mb-0 tw-font-semibold">Where is it happening?</h4>
            <div>{event.location}</div>

            <h4 className="tw-mb-0 tw-font-semibold">When is it?</h4>
            <div>{dateFnsFormat(parseISO(event.start), "MM/dd/yyyy")}</div>

            <h4 className="tw-mb-0 tw-font-semibold">How big is it?</h4>
            <div>{event.capacity} attendees</div>

            <h4 className="tw-mb-0 tw-font-semibold">
              What exposure does this event provide?
            </h4>
            <div>{event.offers_text}</div>
          </div>
        </div>
        <div className="tw-p-4 laptop-up:tw-p-8">
          {props.permissions["events.update"] ? (
            <>
              <div>
                <a href={props.urls.update}>Edit</a>
              </div>
              <div>
                <a href={props.urls.manage}>Back to my events</a>
              </div>
            </>
          ) : (
            <>
              <a href={props.urls.list}>Back to events</a>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

Detail.propTypes = {};

export { Detail };
