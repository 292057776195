import React from "react";
import * as moment from "moment";
import isEmpty from "lodash/isEmpty";
import { WhiteCard } from "js/components/common/Widgets";

const CampaignCard = ({ campaign, ...props }) => {
  const campaignActivity = props.match_types.find(
    tag => tag.slug === campaign.activities[0]
  );

  return (
    <WhiteCard className="tw-mt-12">
      {campaignActivity && <div>{campaignActivity.name}</div>}
      <div>{campaign.name}</div>
      <div>{moment(campaign.go_live).format("MMMM Do, YYYY")}</div>
      <div>
        <a href={campaign.detail_url}>View</a>
      </div>
      <div>
        <a href={campaign.update_url}>Edit</a>
      </div>
      <div>
        <a href={campaign.partners_url}>Partners</a>
      </div>
    </WhiteCard>
  );
};

const Manage = ({ campaigns, ...props }) => (
  <div className="tw-flex-1 tw-bg-gray-100">
    <div className="tw-max-w-4xl tw-mx-auto tw-px-4 tw-py-12">
      <h3>My Campaigns</h3>
      {!isEmpty(campaigns) ? (
        campaigns
          .sort((f, s) => (f.go_live < s.go_live ? -1 : 1))
          .map(campaign => (
            <CampaignCard key={campaign.id} campaign={campaign} {...props} />
          ))
      ) : (
        <div className="tw-font-semibold tw-text-2xl tw-text-parsnip-charcoal tw-my-6">
          No campaigns yet!
        </div>
      )}
      <div className="tw-mt-12">
        <a className="tw-button cta" href={props.urls.create_campaign}>
          Create new campaign
        </a>
      </div>
    </div>
  </div>
);

export default Manage;
