import React from "react";
import PropTypes from "prop-types";
import { setPropTypes, pure, compose } from "recompose";

const withPropTypes = setPropTypes({
  brands: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      marketing_logo: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      profile_url: PropTypes.string.isRequired
    })
  )
});

const BrandsPure = ({ brands }) => (
  <>
    <ul className="brandpage__brandgrid tw-mt-8">
      {brands.map(brand => (
        <li className="brandpage__brand tw-p-2 tablet-up:tw-p-4" key={brand.id}>
          <a href={brand.profile_url}>
            <div className="image is-square">
              <img
                className="tw-object-contain"
                src={brand.marketing_logo}
                alt={`${brand.name} Logo`}
              />
            </div>
          </a>
        </li>
      ))}
    </ul>
  </>
);

const Brands = compose(
  withPropTypes,
  pure
)(BrandsPure);

export { Brands };
