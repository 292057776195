import React from "react";
import { useFormContext, ErrorMessage, Controller } from "react-hook-form";
import classNames from "classnames";
import Cleave from "cleave.js/react";
import { HelpText, ErrorComponent } from "js/components/common/Widgets";

import { getUniqueId } from "js/components/common/helpers";

const CurrencyField = ({ name, label, helpText }) => {
  const { errors, formState } = useFormContext();
  const inputId = `${name}_${getUniqueId()}`;
  return (
    <div className="field">
      <label htmlFor={inputId} className="label">
        {label}
      </label>

      <Controller
        id={inputId}
        name={name}
        onChange={([e]) => {
          return e.target.rawValue;
        }}
        as={
          <Cleave
            options={{
              prefix: "$",
              numeral: true,
              numeralPositiveOnly: true,
              numeralDecimalScale: 0,
              numeralThousandsGroupStyle: "thousand",
              rawValueTrimPrefix: true
            }}
            className={classNames("tw-input", {
              "is-danger": errors[name] && formState.touched[name]
            })}
          />
        }
      />
      <ErrorMessage name={name} errors={errors} as={ErrorComponent} />
      <HelpText text={helpText} />
    </div>
  );
};

export default CurrencyField;
