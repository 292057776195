import React, { useState, useCallback } from "react";
import { useFormContext, ErrorMessage } from "react-hook-form";
import classNames from "classnames";
import prependHttp from "prepend-http";

import { HelpText, ErrorComponent } from "js/components/common/Widgets";

import { getUniqueId } from "js/components/common/helpers";

const TextField = ({
  name,
  type,
  label,
  helpText,
  placeholder,
  prefix,
  autofocus
}) => {
  const { register, getValues, setValue, errors, formState } = useFormContext();
  const [prefixWidth, setPrefixWidth] = useState(0);
  const sizeRef = useCallback(node => {
    if (node !== null) {
      setPrefixWidth(node.getBoundingClientRect().width);
    }
  }, []);
  const inputId = `${name}_${getUniqueId()}`;

  // Inline style sets input padding to prefix width or the default Bulma padding value
  return (
    <div className="field">
      <label className="label" htmlFor={inputId}>
        {label}
      </label>
      <div className="tw-relative">
        {prefix && (
          <div
            ref={sizeRef}
            className="tw-absolute tw-flex tw-items-center tw-h-full tw-pl-2 tw-z-10 tw-text-gray-500 tw-font-semibold"
          >
            <span>{prefix}</span>
          </div>
        )}
        <input
          id={inputId}
          name={name}
          type={type || "text"}
          className={classNames("tw-input", {
            "is-error": errors[name] && formState.touched[name]
          })}
          style={{ paddingLeft: prefixWidth || "1rem" }}
          placeholder={placeholder}
          autoFocus={autofocus}
          ref={register}
          onBlur={e => {
            const value = getValues()[name];
            if (type === "url") {
              if (!!value) {
                const updatedValue = prependHttp(value);
                setValue(name, updatedValue, true);
              }
            }
          }}
        />
      </div>
      <ErrorMessage name={name} errors={errors} as={ErrorComponent} />
      <HelpText text={helpText} />
    </div>
  );
};

const TextAreaField = ({
  name,
  label,
  rows,
  helpText,
  placeholder,
  autofocus
}) => {
  const { register, errors, formState } = useFormContext();
  const inputId = `${name}_${getUniqueId()}`;
  return (
    <div className="field">
      <label htmlFor={inputId} className="label">
        {label}
      </label>

      <textarea
        id={inputId}
        name={name}
        className={classNames("tw-input", {
          "is-danger": errors[name] && formState.touched[name]
        })}
        rows={rows}
        placeholder={placeholder}
        autoFocus={autofocus}
        ref={register}
      ></textarea>
      <ErrorMessage name={name} errors={errors} as={ErrorComponent} />
      <HelpText text={helpText} />
    </div>
  );
};

export { TextField, TextAreaField };
