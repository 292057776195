/*******************************************************
  Filter
*******************************************************/

import React, { useContext, useState } from "react";
import styled from "styled-components";
import Checkbox from "./Checkbox";
import WindowContext from "./WindowContext";

const FilterWrapper = styled.div`
  width: 16rem;
  margin-right: 5rem;
  flex: 0 0 auto;
`;

const MobileFilterWrapper = styled.div`
  width: 20rem;
  background: white;
  padding: 2rem 2rem 0 2rem;
  position: absolute;
  z-index: 10;
  top: 0;
  bottom: 0;
  transition: 300ms all ease-out 0ms;

  display: flex;
  flex-direction: column;

  /* Needs account for left padding of container */
  left: -21.5rem;
  &.active {
    left: -1.5rem;
  }
`;

const Exit = styled.div`
  position: absolute;
  top: 24px;
  right: 20px;
  height: 1rem;
  width: 1rem;
  background: url(${props => props.src}) no-repeat 50% 50% / contain;
`;

const TitleWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

const FilterToggle = styled.div`
  padding: 6px;
  top: 0px;
  right: 3px;
  position: absolute;
  cursor: pointer;
`;

const ToggleIcon = styled.img`
  height: 12px;
  width: 12px;
  object-fit: contain;
`;

const Filters = ({ options, activeFilters, update, slug }) => {
  const [abridge, toggleAbridge] = useState(Date.now());
  const abridged = [...options].sort((a, b) => b.active - a.active).slice(0, 4);

  const LengthToggle = styled.div`
    margin-top: 0.5rem;
    margin-left: 2rem;
    cursor: pointer;
  `;

  const activeIds = activeFilters.map(f => f.id);

  return abridge ? (
    <>
      {abridged.map((option, index) => (
        <Checkbox
          key={index}
          label={option.name}
          checked={activeIds.includes(option.id)}
          data-id={option.id}
          data-name={option.name}
          data-slug={option.slug}
          data-type={slug}
          onClick={update}
        />
      ))}
      <LengthToggle onClick={() => toggleAbridge(false)}>+ More</LengthToggle>
    </>
  ) : (
    <>
      {[...options]
        .sort((a, b) => b.active - a.active)
        .map((option, index) => (
          <Checkbox
            label={option.name}
            key={index}
            checked={activeIds.includes(option.id)}
            data-id={option.id}
            data-name={option.name}
            data-slug={option.slug}
            data-type={slug}
            onClick={update}
          />
        ))}
      <LengthToggle onClick={() => toggleAbridge(Date.now())}>
        - Less
      </LengthToggle>
    </>
  );
};

export default props => {
  const [show, setShow] = useState(props.filters.map(filter => filter.slug));
  const screenSizes = useContext(WindowContext);

  const toggleFilter = e => {
    show.includes(e.target.dataset.filter)
      ? setShow(show.filter(s => s !== e.target.dataset.filter))
      : setShow([...show, e.target.dataset.filter]);
  };

  return (
    <>
      {screenSizes.laptopUp ? (
        <FilterWrapper>
          <h4 className="tw-font-semibold tw-h-10 tw-flex tw-items-center">
            <span>Filter</span>
          </h4>
          <div className="tw-h-px tw-w-full tw-bg-gray-400"></div>
          <div className="tw-flex tw-flex-col tw-mb-8">
            {props.filters.map((filter, index) => (
              <div className="tw-text-base" key={index}>
                <TitleWrapper>
                  <span className="tw-font-semibold">{filter.title}</span>
                  <FilterToggle
                    data-filter={filter.slug}
                    onClick={toggleFilter}
                  >
                    <ToggleIcon
                      data-filter={filter.slug}
                      src={
                        show.includes(filter.slug)
                          ? require("./icon-minus.png")
                          : require("./icon-plus.png")
                      }
                    />
                  </FilterToggle>
                </TitleWrapper>
                {show.includes(filter.slug) && (
                  <Filters
                    options={filter.options}
                    activeFilters={props.activeFilters}
                    update={props.update}
                    slug={filter.slug}
                  />
                )}
              </div>
            ))}
          </div>
        </FilterWrapper>
      ) : (
        <MobileFilterWrapper className={props.showMobileMenu ? "active" : ""}>
          <Exit
            src={require("./icon-x.png")}
            onClick={props.toggleMobileFilterMenu}
          />
          <h4 className="tw-font-semibold">Filter</h4>
          <div className="tw-h-px tw-w-full tw-bg-gray-400"></div>
          <div className="tw-flex tw-flex-col tw-mb-8 tw-overflow-y-scroll">
            {props.filters.map((filter, index) => (
              <div className="tw-text-base" key={index}>
                <TitleWrapper>
                  <span className="tw-font-semibold">{filter.title}</span>
                  <FilterToggle
                    data-filter={filter.slug}
                    onClick={toggleFilter}
                  >
                    <ToggleIcon
                      data-filter={filter.slug}
                      src={
                        show.includes(filter.slug)
                          ? require("./icon-minus.png")
                          : require("./icon-plus.png")
                      }
                    />
                  </FilterToggle>
                </TitleWrapper>
                {show.includes(filter.slug) && (
                  <Filters
                    options={filter.options}
                    activeFilters={props.activeFilters}
                    update={props.update}
                    slug={filter.slug}
                  />
                )}
              </div>
            ))}
          </div>
        </MobileFilterWrapper>
      )}
    </>
  );
};
