import React from "react";
import isEmpty from "lodash/isEmpty";
import { useDataApi } from "js/components/common/helpers";

import { CampaignInfo } from "./Detail";
import {
  WhiteCard,
  Logo,
  SocialIcon,
  Label
} from "js/components/common/Widgets";

import twitterGlyph from "images/profile/twitter-glyph.svg";
import instagramGlyph from "images/profile/instagram-glyph.svg";
import facebookGlyph from "images/profile/facebook-glyph.svg";
import verifiedGlyph from "images/base/verified.svg";

const PartnerCard = ({
  partnership: initialPartnership,
  hasApproved,
  onApproved
}) => {
  const [partnership, setPartnership] = React.useState(initialPartnership);
  const [{ isLoading, isError, data }, sendRequest] = useDataApi(
    { url: partnership.update_url, method: "patch" },
    {
      onSuccess: response => {
        if (response.data.partnership.approved) {
          setPartnership(response.data.partnership);
          onApproved();
        }
      },
      onError: error => {
        console.error("Partnership approval error");
        console.dir(error);
        throw error;
      }
    }
  );
  return (
    <WhiteCard className="tw-mt-8">
      <div className="campaign__upper-layout">
        <a href={partnership.organization.profile_url} target="_blank">
          <Logo src={partnership.organization.logo} />
        </a>
        <div>
          <a href={partnership.organization.profile_url}>
            <Label className="tw-p-2">{partnership.organization.name}</Label>
          </a>
          <div className="tw-p-2">{partnership.organization.description}</div>
          {(partnership.organization.instagram ||
            partnership.organization.twitter ||
            partnership.organization.facebook) && (
            <div className="tw-py-2">
              {partnership.organization.instagram && (
                <a
                  href={`https://instagram.com/${partnership.organization.instagram}`}
                  target="_blank"
                  className="tw-inline-block tw-p-2"
                >
                  <SocialIcon src={instagramGlyph} alt="Instagram" />
                </a>
              )}
              {partnership.organization.twitter && (
                <a
                  href={`https://twitter.com/${partnership.organization.twitter}`}
                  target="_blank"
                  className="tw-inline-block tw-p-2"
                >
                  <SocialIcon src={twitterGlyph} alt="Twitter" />
                </a>
              )}
              {partnership.organization.facebook && (
                <a
                  href={`https://facebook.com/${partnership.organization.facebook}`}
                  target="_blank"
                  className="tw-inline-block tw-p-2"
                >
                  <SocialIcon src={facebookGlyph} alt="Facebook" />
                </a>
              )}
            </div>
          )}
        </div>
      </div>
      {hasApproved ? (
        <>
          {partnership.approved && (
            <>
              <hr className="tw-h-px tw-my-4" />
              <div className="tw-flex tw-justify-end tw-pt-4">
                <SocialIcon src={verifiedGlyph} />
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <hr className="tw-h-px tw-my-4" />
          <div className="tw-text-right tw-pt-4">
            <button
              className="tw-button tw-border-gray-400"
              onClick={() =>
                sendRequest({
                  partnership: partnership.id,
                  approved: true
                })
              }
            >
              Select
            </button>
          </div>
        </>
      )}
    </WhiteCard>
  );
};

const Partners = ({ campaign, ...props }) => {
  // Has a partner already been chosen for this campaign?
  const [hasApproved, setHasApproved] = React.useState(
    campaign.campaign_partners.some(partnership => partnership.approved)
  );
  const onApproved = () => {
    setHasApproved(true);
    // TODO: redirect to the specific thread
    window.location = props.urls.inbox;
  };

  return (
    <div className="tw-flex-1 tw-bg-gray-100">
      <div className="tw-max-w-4xl tw-mx-auto tw-px-4 tw-py-12">
        <WhiteCard>
          <CampaignInfo campaign={campaign} {...props} />
        </WhiteCard>
        {!isEmpty(campaign.campaign_partners) ? (
          <div className="tw-mt-12">
            <h3>Partner Requests</h3>
            {campaign.campaign_partners.map(partnership => (
              <PartnerCard
                key={partnership.id}
                partnership={partnership}
                hasApproved={hasApproved}
                onApproved={onApproved}
              />
            ))}
          </div>
        ) : (
          <div className="tw-font-semibold tw-text-2xl tw-text-parsnip-charcoal tw-px-8 tw-mt-12">
            No partner requests yet!
          </div>
        )}
      </div>
    </div>
  );
};
Partners.propTypes = {};
export default Partners;
