import React from "react";
import PropTypes from "prop-types";

import * as Yup from "yup";
import {
  TextField,
  TextAreaField,
  TagArrayField,
  DateField,
  BooleanTagField
} from "js/components/common/forms/fields";
import { Page, Wizard } from "js/components/common/forms/Wizard";

const OverviewPage1 = ({ match_types }) => {
  const ValidationSchema = Yup.object({
    activities: Yup.string().required()
  });
  return (
    <>
      <div className="tw-mb-12">
        <h3>Campaign Overview</h3>
      </div>
      <Page
        defaultValues={{ activities: "" }}
        validationSchema={ValidationSchema}
        getNextPage={() => OverviewPage2}
      >
        <TagArrayField
          name="activities"
          label="What do you want to do with a partner?"
          options={match_types}
          helpText="Select one"
          multiple={false}
        />
      </Page>
    </>
  );
};

const OverviewPage2 = ({ certifications }) => {
  const ValidationSchema = Yup.object({ certifications: Yup.array().min(1) });
  return (
    <>
      <div className="tw-mb-12">
        <h3>Campaign Overview</h3>
      </div>
      <Page
        defaultValues={{ certifications: [] }}
        validationSchema={ValidationSchema}
        getNextPage={() => OverviewPage3}
      >
        <TagArrayField
          name="certifications"
          label="What organizational values would you like your campaign to be aligned around?"
          options={certifications}
          helpText="Select all that apply"
        />
      </Page>
    </>
  );
};

const OverviewPage3 = ({ product_certifications }) => {
  const ValidationSchema = Yup.object({
    product_certifications: Yup.array().min(1)
  });
  return (
    <>
      <div className="tw-mb-12">
        <h3>Campaign Overview</h3>
      </div>
      <Page
        defaultValues={{ product_certifications: [] }}
        validationSchema={ValidationSchema}
        getNextPage={() => OverviewPage4}
      >
        <TagArrayField
          name="product_certifications"
          label="What product attributes would you like your campaign to be aligned around?"
          options={product_certifications}
          helpText={"Select all that apply"}
        />
      </Page>
    </>
  );
};

const OverviewPage4 = ({ topics }) => {
  const ValidationSchema = Yup.object({
    themes: Yup.array().min(1)
  });
  return (
    <>
      <div className="tw-mb-12">
        <h3>Campaign Overview</h3>
      </div>
      <Page
        defaultValues={{ themes: [] }}
        validationSchema={ValidationSchema}
        getNextPage={() => DetailsPage1}
      >
        <TagArrayField
          name="themes"
          label="What themes would you like your campaign to be aligned around?"
          options={topics}
          helpText={"Select all that apply"}
        />
      </Page>
    </>
  );
};

const DetailsPage1 = ({ platforms, responsibleOrgOptions }) => {
  const ValidationSchema = Yup.object({
    platforms: Yup.array().min(1),
    copy_organization: Yup.mixed().required(),
    photos_required: Yup.boolean().required()
  });
  return (
    <>
      <div className="tw-mb-12">
        <h3>Campaign Details</h3>
      </div>
      <Page
        defaultValues={{
          platforms: [],
          copy_organization: null,
          photos_required: null
        }}
        validationSchema={ValidationSchema}
        getNextPage={values => {
          if (values.photos_required == true) {
            return PhotosOrgPage;
          } else {
            return ShippingPage1;
          }
        }}
      >
        <TagArrayField
          name="platforms"
          label="Select the platforms you'd like the final output of this campaign to be created for"
          options={platforms}
          helpText={"Select all that apply"}
        />
        <TagArrayField
          name="copy_organization"
          label="Who would you like to be responsible for campaign copy?"
          options={responsibleOrgOptions}
          multiple={false}
        />
        <BooleanTagField
          name="photos_required"
          label="Are photos necessary of all products involved (photo taken of products together)?"
        />
      </Page>
    </>
  );
};

const PhotosOrgPage = ({ responsibleOrgOptions }) => {
  const ValidationSchema = Yup.object({
    photos_organization: Yup.mixed().required()
  });
  return (
    <>
      <div className="tw-mb-12">
        <h3>Campaign Prep</h3>
      </div>
      <Page
        defaultValues={{ photos_organization: null }}
        validationSchema={ValidationSchema}
        getNextPage={() => ShippingPage1}
      >
        <TagArrayField
          name="photos_organization"
          label="Who would you like to be responsible for product photography?"
          options={responsibleOrgOptions}
          multiple={false}
        />
      </Page>
    </>
  );
};

const ShippingPage1 = ({}) => {
  const ValidationSchema = Yup.object({
    product_shipping_required: Yup.boolean().required()
  });
  return (
    <>
      <div className="tw-mb-12">
        <h3>Campaign Prep</h3>
      </div>
      <Page
        defaultValues={{ product_shipping_required: null }}
        validationSchema={ValidationSchema}
        getNextPage={values => {
          if (values.product_shipping_required == true) {
            return ShippingPage2;
          } else {
            return PurchasePage;
          }
        }}
      >
        <BooleanTagField
          name="product_shipping_required"
          label="Will product need to be shipped (either to you or to potential partners)?"
        />
      </Page>
    </>
  );
};

const ShippingPage2 = ({ responsibleOrgOptions, organization }) => {
  const ValidationSchema = Yup.object({
    product_recipient_organization: Yup.mixed().required()
  });
  return (
    <>
      <div className="tw-mb-12">
        <h3>Campaign Prep</h3>
      </div>
      <Page
        defaultValues={{ product_recipient_organization: null }}
        validationSchema={ValidationSchema}
        getNextPage={values => {
          if (values.product_recipient_organization == organization.id) {
            return ShippingPage3;
          } else {
            return FinalizingPage;
          }
        }}
      >
        <TagArrayField
          name="product_recipient_organization"
          label="Who would you like to receive product?"
          options={responsibleOrgOptions}
          multiple={false}
        />
      </Page>
    </>
  );
};

const ShippingPage3 = ({}) => {
  const ValidationSchema = Yup.object({
    product_recipient_address: Yup.string().required()
  });
  return (
    <>
      <div className="tw-mb-12">
        <h3>Campaign Prep</h3>
      </div>
      <Page
        defaultValues={{ product_recipient_address: "" }}
        validationSchema={ValidationSchema}
        getNextPage={() => FinalizingPage}
      >
        <TextAreaField
          name="product_recipient_address"
          label="To what address will product need to be shipped?"
          rows={3}
        />
      </Page>
    </>
  );
};

const PurchasePage = ({}) => {
  const ValidationSchema = Yup.object({
    product_purchase_required: Yup.boolean().required()
  });
  return (
    <>
      <div className="tw-mb-12">
        <h3>Campaign Prep</h3>
      </div>
      <Page
        defaultValues={{ product_purchase_required: null }}
        validationSchema={ValidationSchema}
        getNextPage={() => FinalizingPage}
      >
        <BooleanTagField
          name="product_purchase_required"
          label="Are you expecting your partner(s) to purchase product (locally or ecomm) for photography?"
        />
      </Page>
    </>
  );
};

const FinalizingPage = ({}) => {
  const ValidationSchema = Yup.object({
    go_live: Yup.date().required(),
    name: Yup.string().required()
  });
  return (
    <>
      <div className="tw-mb-12">
        <h3>Finalizing Your Campaign</h3>
      </div>
      <Page
        defaultValues={{ go_live: null, name: "" }}
        validationSchema={ValidationSchema}
        getNextPage={() => {}}
      >
        <DateField
          name="go_live"
          label="What date would you like your campaign to go live?"
        />
        <TextField
          name="name"
          label="What would you like to call your campaign?"
        />
      </Page>
    </>
  );
};

const Create = props => {
  const submissionConfig = {
    url: props.campaignCreateUrl,
    method: "post"
  };

  const responsibleOrgOptions = [
    { id: 0, name: "Me", slug: props.organization.id },
    { id: 1, name: "Potential partner(s)", slug: "" }
  ];
  return (
    <section>
      <div className="tw-max-w-3xl tw-mx-auto">
        <Wizard
          initialPage={OverviewPage1}
          onSubmit={response => {
            if (response.data.redirect) {
              window.location = response.data.redirect;
            }
          }}
          submissionConfig={submissionConfig}
          {...props}
        >
          <OverviewPage1 />
          <OverviewPage2 />
          <OverviewPage3 />
          <OverviewPage4 />
          <DetailsPage1 responsibleOrgOptions={responsibleOrgOptions} />
          <PhotosOrgPage responsibleOrgOptions={responsibleOrgOptions} />
          <ShippingPage1 />
          <ShippingPage2 responsibleOrgOptions={responsibleOrgOptions} />
          <ShippingPage3 />
          <PurchasePage />
          <FinalizingPage />
        </Wizard>
      </div>
    </section>
  );
};

Create.propTypes = {};

export default Create;
