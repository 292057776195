import React, { useState } from "react";

// TODO: centralize initialize and CSS import
import "react-dates/initialize";
import { SingleDatePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import "./react_dates_overrides.scss";
import * as moment from "moment";

import classNames from "classnames";
import { useFormContext, ErrorMessage, Controller } from "react-hook-form";

import { ErrorComponent, HelpText } from "js/components/common/Widgets";

import { getUniqueId } from "js/components/common/helpers";

const YearField = ({ name, label, helpText, placeholder, autofocus }) => {
  const { register, errors, formState } = useFormContext();
  const inputId = `${name}_${getUniqueId()}`;
  return (
    <div className="field">
      <label htmlFor={inputId} className="label">
        {label}
      </label>

      <input
        id={inputId}
        name={name}
        type="number"
        min={1200}
        max={new Date().getFullYear()}
        step="1"
        className={classNames("tw-input", {
          "is-danger": errors[name] && formState.touched[name]
        })}
        placeholder={placeholder}
        autoFocus={autofocus}
        ref={register}
      />
      <ErrorMessage name={name} errors={errors} as={ErrorComponent} />
      <HelpText text={helpText} />
    </div>
  );
};
const DatePicker = ({ date, onChange }) => {
  const [focused, setFocused] = useState(false);

  return (
    <SingleDatePicker
      onDateChange={date => onChange(date)}
      onFocusChange={({ focused }) => setFocused(focused)}
      focused={focused}
      date={date}
      displayFormat="YYYY/MM/DD"
      placeholder=""
      block={true}
      numberOfMonths={1}
      hideKeyboardShortcutsPanel={true}
    />
  );
};
const DateField = ({ name, label, helpText }) => {
  const { errors, watch, control } = useFormContext();
  const inputId = `${name}_${getUniqueId()}`;
  // Convert stored value to moment object for react-dates (we don't want to use Moment as our date storage type so that we can just send the value straight to an API)
  let value = watch(name);
  if (
    typeof value === "string" ||
    value instanceof String ||
    value instanceof Date
  ) {
    value = moment(value);
  }

  return (
    <div className="field">
      <label htmlFor={inputId} className="label">
        {label}
      </label>
      <HelpText text={helpText} />
      <Controller
        id={inputId}
        name={name}
        control={control}
        onChange={([value]) => {
          return { value: value.format() };
        }}
        as={<DatePicker date={value} />}
      />
      <ErrorMessage name={name} errors={errors} as={ErrorComponent} />
    </div>
  );
};

export { YearField, DateField };
