import React from "react";
import { lifecycle, pure, compose } from "recompose";

const escHandler = (event, escFunction) => {
  if (event.keyCode === 27) {
    escFunction();
  }
};

const withLifeCycleMethods = lifecycle({
  componentDidUpdate({ isOpen }) {
    // Detect opening/closing modals and prevent the body from scrolling
    if (isOpen !== this.props.isOpen) {
      if (this.props.isOpen) {
        document.body.classList.add("is-clipped");
        document.addEventListener(
          "keydown",
          e => {
            escHandler(e, this.props.onClose);
          },
          false
        );
      } else {
        document.body.classList.remove("is-clipped");
        document.removeEventListener(
          "keydown",
          e => {
            escHandler(e, this.props.onClose);
          },
          false
        );
      }
    }
  }
});

const ModalFormPure = ({
  isOpen,
  title,
  canSubmit,
  handleSubmit,
  isSubmitting,
  showSubmitButton = true,
  submitButtonText = "Submit",
  onClose,
  ...props
}) => (
  <>
    {isOpen && (
      <div>
        <div className="profile__modal-wrapper">
          <div className="profile__infocard-overlay" onClick={onClose} />
          <div className="tw-max-w-3xl tw-w-full">
            <div className="profile__infocard tw-z-30">
              <button className="profile__infocard-close" onClick={onClose}>
                <span className="profile__infocard-close-box">
                  <span className="profile__infocard-close-inner" />
                </span>
              </button>
              <div className="profile__infocard-header">
                <div className="tw-flex tw-justify-start">
                  <h3 className="tw-flex tw-items-center tw-m-0">{title}</h3>
                </div>
              </div>
              <div className="profile__infocard-body">{props.children}</div>
              {showSubmitButton && (
                <div className="tw-flex tw-justify-start tw-mt-8 tw-mb-8 tw-px-8">
                  <button
                    type="button"
                    onClick={handleSubmit}
                    disabled={!canSubmit}
                    className="tw-button cta profile__infocard-save"
                  >
                    {submitButtonText}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )}
  </>
);

const ModalForm = compose(
  withLifeCycleMethods,
  pure
)(ModalFormPure);

export { ModalForm };
