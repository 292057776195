import React from "react";
import { useForm, ErrorMessage } from "react-hook-form";
import * as Yup from "yup";
import { useDataApi } from "js/components/common/helpers";
import { ErrorComponent } from "js/components/common/BulmaFormField";

const ReplySchema = Yup.object().shape({
  content: Yup.string()
    .required()
    .min(1)
});
const ThreadReplyForm = ({ thread, onReply }) => {
  const {
    register,
    unRegister,
    errors,
    watch,
    handleSubmit,
    reset,
    setError,
    clearError,
    setValue,
    getValues,
    triggerValidation,
    control,
    formState
  } = useForm({
    mode: "onSubmit",
    defaultValues: { content: "" },
    validationSchema: ReplySchema
  });
  const [{ isLoading, isError, data }, sendReply] = useDataApi(
    { url: thread.reply_url, method: "patch" },
    {
      onSuccess: result => {
        onReply(result);
        reset({});
      },
      onError: error => {
        // console.dir("form error");
      }
    }
  );

  const onSubmit = values => {
    sendReply(values);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <input
        name="content"
        ref={register}
        className="tw-input"
        autoComplete="off"
      />
      <ErrorMessage errors={errors} name="content" as={ErrorComponent} />
      <button type="submit" className="tw-button">
        Send
      </button>
    </form>
  );
};
export default ThreadReplyForm;
