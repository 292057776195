import React from "react";
import PropTypes from "prop-types";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "js/components/common/axios";
import debounce from "lodash/debounce";

import {
  BulmaTextField,
  BulmaTextAreaField,
  BulmaDateField,
  BulmaCurrencyField,
  BulmaFieldArray,
  BulmaAsyncSelectField,
  BulmaCheckboxField
} from "js/components/common/BulmaFormField";

const initialValues = {
  name: "",
  url: "",
  description: "",
  capacity: "",
  start: null,
  budget: "",
  location: "",
  offers_text: "",
  products: [],
  audiences: [],
  asks: [],
  consent: true
};
const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  url: Yup.string().url(),
  description: Yup.string().required(),
  capacity: Yup.number().integer(),
  start: Yup.date().required(),
  budget: Yup.number(),
  location: Yup.string(),
  offers_text: Yup.string(),
  products: Yup.array(),
  audiences: Yup.array().min(1),
  asks: Yup.array(),
  consent: Yup.boolean()
});

const Create = props => {
  // TODO: centralize this. it's used in other event components
  const debouncedFetchProducts = debounce((inputValue, callback) => {
    axios
      .get(props.urls.product_type_list, {
        params: {
          name: inputValue
        }
      })
      .then(result => {
        callback(result.data);
      });
  }, 250);

  return (
    <section>
      <div className="tw-max-w-2xl tw-mx-auto">
        <h3>Create an event</h3>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={(values, actions) => {
            axios
              .post(props.urls.create, values)
              .then(response => {
                if (response.data.redirect) {
                  window.location = response.data.redirect;
                } else {
                  actions.resetForm();
                }
                return response;
              })
              .catch(error => actions.setErrors(error.response.data))
              .finally(() => actions.setSubmitting(false));
          }}
        >
          {formik => (
            <Form>
              <Field
                name="name"
                label="What is the name of your event?"
                component={BulmaTextField}
                autofocus={true}
              />
              <Field
                name="url"
                label="Do you have a link to share? (Eventbrite or other?)"
                type="url"
                component={BulmaTextField}
                placeholder="https://myeventinfo.com"
              />
              <Field
                name="description"
                label="Provide a brief summary of your event"
                component={BulmaTextAreaField}
              />

              <BulmaFieldArray
                name="asks"
                label="What are you looking for in a brand partner?"
                options={props.match_types}
                helpText="Select as many as you'd like"
                form={formik}
              />
              <Field
                name="products"
                label="What products are you looking for?"
                helpText="Start typing to search"
                component={BulmaAsyncSelectField}
                multiple={true}
                fetchOptions={(input, callback) => {
                  if (!input) {
                    return Promise.resolve({ options: [] });
                  }
                  debouncedFetchProducts(input, callback);
                }}
              />
              <Field
                name="location"
                label="Where is the event?"
                component={BulmaTextField}
                placeholder="Anytown, CA"
              />
              <Field
                name="capacity"
                type="number"
                label="For how many people?"
                component={BulmaTextField}
                placeholder="0"
              />
              <Field
                name="budget"
                label="What is your budget?"
                component={BulmaCurrencyField}
              />
              <Field
                name="start"
                label="Start Date"
                component={BulmaDateField}
              />

              <BulmaFieldArray
                name="audiences"
                label="Who is your target audience for this event in particular?"
                options={props.audiences}
                helpText="Select all that apply"
                form={formik}
              />

              <Field
                name="offers_text"
                label="What exposure are you willing to provide in exchange for product? (Be as specific as possible.)"
                component={BulmaTextAreaField}
              />
              <Field
                name="consent"
                label="Can we share this event in our newsletter and social channels?"
                component={BulmaCheckboxField}
              />
              <button type="submit" className="tw-button cta large tw-mt-12">
                Submit
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </section>
  );
};

Create.propTypes = {
  urls: PropTypes.shape({
    create: PropTypes.string.isRequired,
    product_type_list: PropTypes.string.isRequired
  }),
  audiences: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired
    })
  ).isRequired
};
export { Create };
