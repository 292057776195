import { withFormik, Form, Field } from "formik";
import classNames from "classnames";
import { pure, withProps, withStateHandlers, compose } from "recompose";
import Dropzone from "react-dropzone";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { Wizard, Page } from "js/components/common/FormWizard";
import { ModalForm } from "js/components/common/Modal";
import {
  BulmaTextAreaField,
  BulmaSelectField
} from "js/components/common/BulmaFormField";

const withImageStateHandlers = withStateHandlers(
  ({ imageUrl }) => ({
    imagePreviewUrl: imageUrl,
    imageUpdated: false,
    isFileError: false
  }),
  {
    onDropAccepted: ({ imagePreviewUrl, imageUpdated }) => file => {
      if (imageUpdated) {
        URL.revokeObjectURL(imagePreviewUrl);
      }
      const previewUrl = URL.createObjectURL(file);
      return {
        imagePreviewUrl: previewUrl,
        isFileError: false,
        imageUpdated: true
      };
    },
    onDropRejected: () => () => {
      return {
        isFileError: true
      };
    },
    onClose: ({ imageUpdated, imagePreviewUrl }, { imageUrl }) => () => {
      if (imageUpdated) {
        URL.revokeObjectURL(imagePreviewUrl);
      }
      // Reset the image preview url state to the initial value
      return { imagePreviewUrl: imageUrl, imageUpdated: false };
    }
  }
);

// TODO: centralize - also used on the registration form
const withFormikEnhancer = withFormik({
  enableReinitialize: true,
  mapPropsToValues: props => ({ ...props.initialValues }),
  validationSchema: props => props.validationSchema,
  handleSubmit: (values, { setSubmitting, setErrors, setStatus, props }) => {
    props
      .handleSubmit(values)
      .then(result => {
        // TODO: status enum?
        setStatus("success");
      })
      .catch(error => {
        // Set formik errors
        setErrors(error.response.data);
      })
      .finally(() => {
        setSubmitting(false);
      });
  }
});

const ImageUpdateFormPure = ({
  fieldName,
  imagePreviewUrl,
  onDropAccepted,
  onDropRejected,
  isFileError,
  ...props
}) => {
  return (
    <ModalForm
      isOpen={props.isOpen}
      title={`Update ${fieldName}`}
      canSubmit={props.dirty}
      handleSubmit={props.handleSubmit}
      isSubmitting={props.isSubmitting}
      submitButtonText="Save"
      onClose={() => {
        props.handleClose();
        props.onClose();
        props.resetForm();
      }}
    >
      <Form>
        <div className="image-uploader">
          <Dropzone
            className="dropzone"
            multiple={false}
            acceptClassName="accept"
            rejectClassName="reject"
            accept="image/jpeg, image/png"
            onDropAccepted={acceptedFiles => {
              const imageFile = acceptedFiles[0];
              props.setFieldValue(fieldName, imageFile);
              onDropAccepted(imageFile);
            }}
            onDropRejected={onDropRejected}
          >
            <div className={classNames("image", fieldName)}>
              <img src={imagePreviewUrl} alt={fieldName} />
            </div>
          </Dropzone>
          <div>Click above or drop an image to update your {fieldName}</div>
          <div className="tw-text-gray-500 tw-text-sm">
            {"(Note: Images must be < 20MB)"}
          </div>
          <div
            className={classNames("file-error", {
              hidden: !isFileError
            })}
          >
            {fieldName} must be an image file
          </div>
        </div>
      </Form>
    </ModalForm>
  );
};

const ImageUpdateForm = compose(
  withImageStateHandlers,
  withFormikEnhancer,
  pure
)(ImageUpdateFormPure);

/*****************
 Connect form
*/

const SimpleList = styled("ul")`
  list-style: none;
  padding-left: 0;
  & li {
    margin-top: 0.5rem;
  }
`;

// Use this pattern to split the profile into multiple update forms
const withConnectInitialProps = withProps(props => ({
  initialValues: {
    types: [],
    description: ""
  },
  // TODO: centralize this with the registration validation schema
  validationSchema: Yup.object().shape({
    description: Yup.string(),
    types: Yup.array().min(1)
  })
}));

const ConnectFormPure = ({ match_types, organization, ...props }) => (
  <ModalForm
    isOpen={props.isOpen}
    title={`Connect with ${organization.name}`}
    canSubmit={props.dirty && props.isValid}
    handleSubmit={props.handleSubmit}
    isSubmitting={props.isSubmitting}
    showSubmitButton={props.status !== "success"}
    submitButtonText={"Connect"}
    closeButtonText={props.status === "success" ? "Close" : "Cancel"}
    onClose={() => {
      props.handleClose();
      props.resetForm();
    }}
  >
    {props.status === "success" ? (
      <div className="tw-mb-8">Thank you! Someone will be in touch soon!</div>
    ) : (
      <Wizard
        showProgress={false}
        showControls={false}
        {...props}
        pages={[
          <Page>
            <Field
              name="types"
              label="What types of opportunities are you interested in?"
              component={BulmaSelectField}
              multiple={true}
              options={match_types}
              helpText="Select all that apply"
            />
            <Field
              name="description"
              label="What other information do they need to know?*"
              component={BulmaTextAreaField}
              rows={4}
            />
            <div className="tw-mb-4 tw-text-sm tw-italic">
              <div>* If you're interested in:</div>
              <SimpleList>
                <li>
                  <span className="tw-font-bold tw-mr-1">
                    A collaborative giveaway:
                  </span>
                  <span>
                    Let them know if you can handle the creative & the timeframe
                  </span>
                </li>
                <li>
                  <span className="tw-font-bold tw-mr-1">
                    Their products being at your event or in a swag bag:
                  </span>
                  <span>
                    Let them know how many you need, the target audience, the
                    event copy/link, and when you need products to arrive
                  </span>
                </li>
                <li>
                  <span className="tw-font-bold tw-mr-1">
                    Digital co-marketing (blog, newsletter exchange, recipe
                    development, etc.):
                  </span>
                  <span>
                    Let them know what you’re envisioning, how your products
                    and/or companies align
                  </span>
                </li>
              </SimpleList>
            </div>
          </Page>
        ]}
      />
    )}
  </ModalForm>
);

const ConnectForm = compose(
  withConnectInitialProps,
  withFormikEnhancer,
  pure
)(ConnectFormPure);

export { ImageUpdateForm, ConnectForm };
