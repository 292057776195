import React, { useState } from "react";
import PropTypes from "prop-types";
import ThreadList from "./ThreadList";
import ThreadView from "./ThreadView";
import ThreadReplyForm from "./ThreadReplyForm";
import styled from "@emotion/styled";

// Multiple scrollable panels.
// Use the CodePen here to experiment more: https://codepen.io/bnut/pen/jvpdPo
const InboxLayout = styled("div")`
  flex: 1; /* Pairs with display: flex from the template html */
  display: flex;
`;
const Sidebar = styled("div")`
  display: flex;
  flex-direction: column;
  width: 300px;
`;
const Content = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Inbox = ({ partnerships }) => {
  // Convert partnership threads to a state object where the keys are the thread ids and the values are the threads (with the campaign added)
  const [threads, updateThreads] = useState(
    partnerships.reduce(
      (acc, cur) => ({
        ...acc,
        [cur.thread.id]: { ...cur.thread, campaign: cur.campaign }
      }),
      {}
    )
  );
  const [activeThreadId, setActiveThreadId] = useState();

  return (
    <InboxLayout>
      <Sidebar>
        <ThreadList threads={threads} handleThreadClicked={setActiveThreadId} />
      </Sidebar>
      <Content>
        {activeThreadId && (
          <>
            <ThreadView thread={threads[activeThreadId]} />
            <ThreadReplyForm
              thread={threads[activeThreadId]}
              onReply={thread => {
                updateThreads({ ...threads, [thread.id]: thread });
              }}
            ></ThreadReplyForm>
          </>
        )}
      </Content>
    </InboxLayout>
  );
};

Inbox.propTypes = {
  threads: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      messages: PropTypes.array
    })
  )
};

export default Inbox;
