import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import tw from "twin.macro";
import { Logo } from "js/components/common/Widgets";

const ThreadListLayout = styled("div")`
  overflow: auto;
  flex: 1 1 0;
  ${tw`border-r border-gray-300`}
`;
const ThreadListItem = styled("div")`
  padding: 1rem;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1rem;
  align-items: center;
  ${tw`border-b border-gray-300`}
`;

const ThreadList = ({ threads, handleThreadClicked }) => (
  <ThreadListLayout className="threadlist">
    {Object.values(threads)
      .sort((first, second) => (first.modified > second.modified ? -1 : 1))
      .map(thread => (
        <ThreadListItem
          key={thread.id}
          onClick={() => {
            handleThreadClicked(thread.id);
          }}
        >
          <Logo src={thread.campaign.owner.logo} size={"3rem"} />
          <div className="tw-font-semibold">{thread.campaign.name}</div>
        </ThreadListItem>
      ))}
  </ThreadListLayout>
);

ThreadList.propTypes = {
  // threads: PropTypes.object.shape(
  //   PropTypes.shape({
  //     id: PropTypes.string.isRequired,
  //     messages: PropTypes.array
  //   })
  // )
};

export default ThreadList;
