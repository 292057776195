import React from "react";
import { pure, compose } from "recompose";

const ServiceProfilePure = ({
  organization,
  permissions: { is_authenticated },
  urls
}) => (
  <>
    <div className="tw-my-4 tw-p-4 tw-border tw-rounded tw-border-gray-400">
      <div className="profilepage__info-heading">Service Providers Brief</div>

      <div className="profilepage__info">
        <div className="profilepage__info-key">Who we are</div>
        <div className="profilepage__info-value">
          <span>{organization.description}</span>
        </div>
      </div>

      <div className="profilepage__info">
        <div className="profilepage__info-key">Our audience</div>
        <div className="profilepage__info-value">
          <span>{organization.audience_text}</span>
        </div>
      </div>
    </div>

    <div className="tw-my-4 tw-p-4 tw-border tw-rounded tw-border-gray-400 tw-relative">
      {!is_authenticated && (
        <div className="profilepage__overlay">
          <a
            href={urls.join}
            className="profilepage__overlay__text tw-text-2xl tablet-up:tw-text-4xl tw-text-gray-700 tw-font-semibold"
          >
            Join Parsnip to see more!
          </a>
        </div>
      )}
      <div className="profilepage__info-heading">Reach out to us for</div>

      <div className="profilepage__info">
        <div className="profilepage__info-key">Types of opportunities</div>
        <div className="profilepage__info-value">
          <span>{organization.asks_text}</span>
        </div>
      </div>
      <div className="profilepage__info">
        <div className="profilepage__info-key">What we can offer</div>
        <div className="profilepage__info-value">
          <span>{organization.offers_text}</span>
        </div>
      </div>
    </div>
  </>
);

const ServiceProfile = compose(pure)(ServiceProfilePure);

export default ServiceProfile;
