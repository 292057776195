import React from "react";
import { pure, compose } from "recompose";
import styled from "@emotion/styled";
// import tw from "tailwind.macro";

// TODO: add prop type checking with either proptypes/hooks or typescript

const DetailGrid = styled("div")`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-auto-rows: 1fr;
  align-items: baseline;
`;
const DetailKey = styled("div")`
  /* ${tw`p-4 text-gray-600 font-bold`} */
`;
const DetailValue = styled("div")`
  /* ${tw`p-4`} */
`;

const EditPure = ({ membership, urls }) => (
  <>
    <a href={urls.member_list} className="tw-p-2 tw-text-sm">
      <span className="icon">
        <i className="fas fa-arrow-left" />
      </span>
      <span>Back to Members List</span>
    </a>
    <div className="tw-p-4 tw-rounded-t tw-flex tw-justify-between tw-items-baseline tw-bg-gray-200">
      <span className="tw-text-2xl">Member Info</span>
      <button className="button is-outline">Edit</button>
    </div>
    <DetailGrid>
      <DetailKey>First Name</DetailKey>
      <DetailValue>{membership.user.first_name}</DetailValue>
      <DetailKey>Last Name</DetailKey>
      <DetailValue>{membership.user.last_name}</DetailValue>
      <DetailKey>Email</DetailKey>
      <DetailValue>{membership.user.email}</DetailValue>
    </DetailGrid>
  </>
);

const Edit = compose(pure)(EditPure);

export { Edit };
