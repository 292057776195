import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

const ThreadViewLayout = styled.div`
  overflow: auto;
  flex: 1 1 0;
`;

const Message = ({ message }) => <div>{message.content}</div>;

const ThreadView = ({ thread }) => {
  return (
    <ThreadViewLayout>
      {thread.messages.map(message => (
        <Message key={message.id} message={message} />
      ))}
    </ThreadViewLayout>
  );
};

ThreadView.propTypes = {};

export default ThreadView;
