/*******************************************************
  Results
*******************************************************/

import React, { useContext } from "react";
import styled from "styled-components";
import truncate from "lodash/truncate";
import WindowContext from "./WindowContext";
import Pagination from "./Pagination";
import defaultLogo from "images/profile/plogo.png";

const ResultsWrapper = styled.div`
  position: relative;
  flex: 1 0 0;
  min-height: 40rem;
`;

const ResultsHeader = styled.div``;

const CardWrapper = styled.div`
  width: 100%;
  transition: all ease 750ms;
`;

const Loading = styled.div`
  width: 100%;
  z-index: 0;
  transition: all ease 750ms;
`;

const Card = styled.div``;

const Image = styled.div`
  background: url(${props => (props.src ? props.src : defaultLogo)}) no-repeat
    50% 50% / cover;
`;

const MobileMenuToggle = styled.div`
  font-size: 16px;
  margin: 5px 0 30px;
  color: #f00;
`;

export default ({
  results: data,
  totalResults,
  numPages,
  pageNum,
  term,
  loading,
  activeFilters,
  update,
  clearTerm,
  onPageChange,
  toggleMobileFilterMenu
}) => {
  const screenSizes = useContext(WindowContext);

  // TODO: Centralize all of this "maxPage" stuff
  const maxPage = 3;

  const Title = () => {
    let string = "";
    if (data && !loading) {
      string = `${
        totalResults > 100 ? "More than 100" : totalResults
      } results found`;
      if (term) {
        string += ` for`;
      }
    } else if (loading) {
      string = "Loading";
    }

    const TermBox = () => (
      <>
        {term && !loading && (
          <div className="tw-inline-flex tw-items-stretch tw-ml-2 tw-bg-gray-200 tw-rounded tw-border tw-border-gray-300">
            <div className="tw-px-2 tw-py-0">
              <span>{term}</span>
            </div>
            <div
              className="tw-px-2 tw-py-0 tw-flex tw-items-center tw-cursor-pointer hover:tw-bg-gray-300 tw-text-sm tw-font-light"
              onClick={clearTerm}
              aria-label="Clear search term"
            >
              &times;
            </div>
          </div>
        )}
      </>
    );

    return (
      <h4 className="tw-font-semibold tw-h-10 tw-flex tw-items-center">
        <span>{string}</span>
        <TermBox></TermBox>
      </h4>
    );
  };

  const FilterBox = props => (
    <div
      className="tw-bg-gray-200 tw-rounded tw-border tw-border-gray-300 tw-font-light tw-text-sm tw-mb-2"
      {...props}
    >
      <div className="tw-inline-block tw-p-2">{props.label}</div>
      <div
        className="tw-inline-block tw-p-2 tw-cursor-pointer hover:tw-bg-gray-300"
        data-id={props.id}
        data-type={props.slug}
        onClick={update}
        aria-label="Remove filter"
      >
        &times;
      </div>
    </div>
  );

  const MaxPageNotice = () => (
    <div className="tw-h-64">
      <p>
        Woot! You’re a master searcher and you’ve gotten to the end of this
        particular search.
      </p>
      <p>
        We’ve limited search results for this release of search and filter to 3
        pages. But don’t despair – just filter your results a bit or enter a new
        search term to discover more on Parsnip!
      </p>
    </div>
  );

  return (
    <ResultsWrapper>
      <ResultsHeader>
        <Title></Title>
        {screenSizes.laptopUp ? (
          <div className="searchpage__filterlist">
            {activeFilters &&
              activeFilters.map(filter => (
                <FilterBox
                  key={filter.id}
                  label={filter.name}
                  id={filter.id}
                  slug={filter.slug}
                />
              ))}
          </div>
        ) : (
          <MobileMenuToggle onClick={toggleMobileFilterMenu}>
            Show filters
          </MobileMenuToggle>
        )}
      </ResultsHeader>

      {loading ? (
        <Loading>
          {Array.from({ length: 10 }, (_, i) => (
            <Card className="searchpage__card" key={i}>
              <div className="tw-w-32 tw-h-32 tw-bg-parsnip-teal tw-rounded-full tw-mr-8 tw-flex-none"></div>
              <div className="tw-flex tw-flex-col tw-w-5/6 tw-opacity-50">
                <div className="tw-w-1/3 tw-h-5 tw-bg-parsnip-charcoal tw-rounded-full tw-mt-1 tw-mb-6"></div>
                <div className="tw-w-full tw-h-4 tw-bg-gray-400 tw-rounded-full tw-mb-3"></div>
                <div className="tw-w-1/3 tw-h-4 tw-bg-gray-400 tw-rounded-full tw-mb-3"></div>
              </div>
            </Card>
          ))}
        </Loading>
      ) : (
        <CardWrapper>
          {pageNum > maxPage ? (
            <MaxPageNotice></MaxPageNotice>
          ) : (
            <>
              {data &&
                data.map((datum, index) => (
                  <Card key={index}>
                    <a
                      href={datum.profile_url}
                      className="searchpage__card"
                      target="_blank"
                    >
                      <Image
                        className="tw-w-32 tw-h-32 tw-rounded-full tw-mr-8 tw-flex-none"
                        src={datum.logo}
                      />
                      <div className="tw-flex tw-flex-col tw-w-full">
                        <h4 className="tw-font-semibold tw-mt-0 tw-mb-4">
                          {datum.name}
                        </h4>
                        <div className="tw-text-base tw-text-parsnip-charcoal">
                          {truncate(datum.description, {
                            length: 180,
                            separator: /,? +/
                          }) || "No Description"}
                        </div>
                      </div>
                    </a>
                  </Card>
                ))}
            </>
          )}
        </CardWrapper>
      )}
      {numPages > 1 && (
        <Pagination
          numPages={numPages}
          pageNum={pageNum}
          onPageChange={onPageChange}
        ></Pagination>
      )}
    </ResultsWrapper>
  );
};
