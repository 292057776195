import React, { useState, useEffect, useCallback } from "react";
import { Field, FieldArray, ErrorMessage } from "formik";
import classNames from "classnames";
import isEmpty from "lodash/isEmpty";
import prependHttp from "prepend-http";
import { css } from "@emotion/core";
import Dropzone from "react-dropzone";
import Tippy from "@tippy.js/react";

const ErrorComponent = ({ children }) => (
  <span
    className="help is-danger tw-font-normal"
    css={css`
      position: absolute;
      top: 100%;
    `}
  >
    {children}
  </span>
);

const defaultEmptyMessage = "Oops, this hasn't been filled out yet!";

const EditableImageField = ({ editing, name, formik, defaultImage }) => {
  const value = formik.values[name];
  const [imagePreviewUrl, setImagePreviewUrl] = useState(value);
  const [imageUpdated, setImageUpdated] = useState(false);
  useEffect(() => {
    if (!editing && imageUpdated) {
      URL.revokeObjectURL(imagePreviewUrl);
      setImagePreviewUrl(value);
      setImageUpdated(false);
    }
  }, [editing]);
  const onDropAccepted = file => {
    if (imageUpdated) {
      URL.revokeObjectURL(imagePreviewUrl);
    }
    const previewUrl = URL.createObjectURL(file);
    setImagePreviewUrl(previewUrl);
    setImageUpdated(true);
  };
  const onDropRejected = () => {
    // TODO
    console.log("drop rejected - do something");
  };
  return (
    <>
      {editing ? (
        <Dropzone
          className="dropzone"
          multiple={false}
          acceptClassName="accept"
          rejectClassName="reject"
          accept="image/jpeg, image/png"
          onDropAccepted={acceptedFiles => {
            const imageFile = acceptedFiles[0];
            formik.setFieldValue(name, imageFile);
            onDropAccepted(imageFile);
          }}
          onDropRejected={onDropRejected}
        >
          <img src={imagePreviewUrl || defaultImage} alt={name} />
        </Dropzone>
      ) : (
        <img src={value || defaultImage} alt={name} />
      )}
    </>
  );
};

const EditableTextField = ({
  type,
  editing,
  name,
  placeholder,
  prefix,
  formik,
  emptyMessage,
  canEdit,
  defaultRender
}) => {
  const value = formik.values[name];
  let [prefixWidth, setPrefixWidth] = useState(0);
  const sizeRef = useCallback(node => {
    if (node !== null) {
      setPrefixWidth(node.getBoundingClientRect().width);
    }
  }, []);

  return (
    <>
      {editing ? (
        <Field name={name}>
          {({ field, form, meta }) => (
            <div className="tw-flex tw-flex-col tw-relative">
              {prefix && (
                <div
                  ref={sizeRef}
                  className="tw-absolute tw-flex tw-items-center tw-h-full tw-pl-2 tw-z-10 tw-text-gray-500 tw-font-semibold"
                >
                  <span>{prefix}</span>
                </div>
              )}
              <input
                type={type || "text"}
                placeholder={placeholder}
                className={classNames("tw-input", {
                  "is-error": meta.error && meta.touched
                })}
                style={{ paddingLeft: prefixWidth || "1rem" }}
                {...field}
                onBlur={e => {
                  if (type === "url") {
                    if (!!value) {
                      const updatedValue = prependHttp(value);
                      formik.setFieldValue(name, updatedValue);
                    }
                  }
                  field.onBlur(e);
                }}
              />
              <ErrorMessage name={name} component={ErrorComponent} />
            </div>
          )}
        </Field>
      ) : value ? (
        defaultRender ? (
          defaultRender({ value })
        ) : (
          <span>{value}</span>
        )
      ) : (
        <h2 className="tw-text-gray-500 tw-text-center">
          {canEdit ? emptyMessage : defaultEmptyMessage}
        </h2>
      )}
    </>
  );
};

const EditableTextArea = ({
  editing,
  name,
  formik,
  canEdit,
  emptyMessage,
  placeholder,
  rows = 3
}) => {
  const value = formik.values[name];
  return (
    <>
      {editing ? (
        <Field name={name}>
          {({ field, form, meta }) => (
            <div className="tw-flex tw-flex-col tw-relative">
              <textarea
                className={classNames("tw-input", {
                  "is-error": meta.error && meta.touched
                })}
                placeholder={placeholder}
                {...field}
                rows={rows}
              />
              <ErrorMessage name={name} component={ErrorComponent} />
            </div>
          )}
        </Field>
      ) : (
        <>
          {value ? (
            <span>{value}</span>
          ) : (
            <h2 className="tw-text-gray-500 tw-text-center">
              {canEdit ? emptyMessage : defaultEmptyMessage}
            </h2>
          )}
        </>
      )}
    </>
  );
};

const EditableYearField = ({ editing, name, formik }) => {
  const value = formik.values[name];
  return (
    <>
      {editing ? (
        <Field name={name}>
          {({ field, form, meta }) => (
            <input
              {...field}
              type="number"
              className="tw-input"
              min={1200}
              max={new Date().getFullYear()}
              step="1"
            />
          )}
        </Field>
      ) : (
        <span>{value}</span>
      )}
    </>
  );
};

const EditableTag = ({ id, label, checked, onChange }) => (
  // Adapted from https://medium.com/@colebemis/building-a-checkbox-component-with-react-and-styled-components-8d3aa1d826dd
  <Tippy content={label} delay={[500, 0]}>
    <label
      className={classNames(
        "tw-tag",
        "tw-tag--editable",
        checked ? ["checked"] : []
      )}
    >
      <input
        type="checkbox"
        value={id}
        checked={checked}
        onChange={onChange}
        className="hideVisually"
      />
      <span>{label}</span>
    </label>
  </Tippy>
);

const TagList = ({ name, options, value }) => (
  <span className="tw-tag-list">
    <FieldArray
      name={name}
      render={arrayHelpers => (
        <>
          {options &&
            // Have to copy the array before sorting it
            [...options]
              .sort((first, second) =>
                first.name.toUpperCase() < second.name.toUpperCase() ? -1 : 1
              )
              .map((option, index) => (
                <EditableTag
                  key={index}
                  id={option.id}
                  label={option.name}
                  checked={value.find(el => el.id === option.id) ? true : false}
                  onChange={e => {
                    if (e.target.checked) {
                      arrayHelpers.push(option);
                    } else {
                      const idx = value.findIndex(el => el.id === option.id);
                      arrayHelpers.remove(idx);
                    }
                  }}
                />
              ))}
        </>
      )}
    />
  </span>
);

// TODO: Make this use Widgets (styled components) and React-Hook-Form
const EditableTagList = ({
  editing,
  name,
  formik,
  options,
  canEdit,
  emptyMessage
}) => {
  const value = formik.values[name];
  return (
    <>
      {editing ? (
        <TagList name={name} options={options} value={value} />
      ) : (
        <>
          {!isEmpty(value) ? (
            <span className="tw-tag-list">
              {// Have to copy the array before sorting it
              [...value]
                .sort((first, second) =>
                  first.name.toUpperCase() < second.name.toUpperCase() ? -1 : 1
                )
                .map(tag => (
                  <Tippy key={tag.id} content={tag.name} delay={[500, 0]}>
                    <div className="tw-tag">
                      <span>{tag.name}</span>
                    </div>
                  </Tippy>
                ))}
            </span>
          ) : (
            <h2 className="tw-text-gray-500 tw-text-center">
              {canEdit ? emptyMessage : defaultEmptyMessage}
            </h2>
          )}
        </>
      )}
    </>
  );
};

export {
  EditableTextField,
  EditableTextArea,
  EditableYearField,
  EditableTagList,
  EditableImageField,
  TagList
};
