import React, { useState, useEffect, useReducer, useRef } from "react";
import produce from "immer";
import assign from "lodash/assign";
import { FormikConsumer } from "formik";
import { setLocale } from "yup";
import axios from "js/components/common/axios";

setLocale({
  mixed: { required: "Required" },
  array: { min: "Please choose at least ${min} option" },
  string: {
    url: "Must be a valid URL",
    min: "Must be at least ${min} characters"
  }
});

const DataFetchActionTypes = {
  FETCH_INIT: "fetch_init",
  FETCH_SUCCESS: "fetch_success",
  FETCH_FAILURE: "fetch_failure"
};

const dataFetchReducer = (state, action) => {
  switch (action.type) {
    case DataFetchActionTypes.FETCH_INIT:
      return {
        ...state,
        isLoading: true,
        isError: false
      };
    case DataFetchActionTypes.FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.payload
      };
    case DataFetchActionTypes.FETCH_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true
      };
    default:
      throw new Error();
  }
};

const useDataApi = (axiosConfig, { onSuccess, onError, onLoading }) => {
  const [requestData, setRequestData] = useState({});

  const [state, dispatch] = useReducer(dataFetchReducer, {
    isLoading: false,
    isError: false,
    data: {}
  });

  // useRef is like instance variables
  const isFirstRun = useRef(true);

  useEffect(() => {
    if (isFirstRun.current) {
      // First run on initial render, do nothing
      isFirstRun.current = false;
      return;
    }
    const fetchData = async values => {
      if (!axiosConfig || !axiosConfig.url) return;

      const axiosRequestConfig = { ...axiosConfig, data: values };

      dispatch({ type: DataFetchActionTypes.FETCH_INIT });

      try {
        const result = await axios(axiosRequestConfig);
        dispatch({
          type: DataFetchActionTypes.FETCH_SUCCESS,
          payload: result.data
        });

        // Call passed in onSuccess handler
        onSuccess(result);
      } catch (error) {
        dispatch({ type: DataFetchActionTypes.FETCH_FAILURE });
        onError(error);
      }
    };

    fetchData(requestData);
  }, [requestData]);

  return [state, setRequestData];
};

// Custom hook for api object updates
function useApiObject(initialState) {
  // Formik doesn't like null values
  // https://github.com/jaredpalmer/formik/issues/568
  Object.keys(initialState).map(key => {
    if (initialState[key] === null) {
      initialState[key] = "";
    }
  });
  const [apiObject, setApiObject] = useState(initialState);
  function updateApiObject(values) {
    Object.keys(values).map(key => {
      if (values[key] === null) {
        values[key] = "";
      }
    });
    // NOTE: we have to use immer (or an equivalent) since withStateHandlers
    // does a shallow compare when merging new state/props, and thus fails to update.
    // Immer does a smart deep clone/compare
    const newObject = produce(apiObject, draftObj => {
      assign(draftObj, values);
    });
    setApiObject(newObject);
  }
  return [apiObject, updateApiObject];
}

const getUniqueId = () => {
  return [...Array(10)]
    .map(i => (~~(Math.random() * 36)).toString(36))
    .join("");
};

const DebugFormik = () => (
  <div
    style={{
      margin: "3rem 0",
      borderRadius: 4,
      background: "#f6f8fa",

      boxShadow: "0 0 1px  #eee inset"
    }}
  >
    <div
      style={{
        textTransform: "uppercase",
        fontSize: 11,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        fontWeight: 500,
        padding: ".5rem",
        background: "#555",
        color: "#fff",
        letterSpacing: "1px"
      }}
    >
      Formik State
    </div>
    <FormikConsumer>
      {({ validationSchema, validate, onSubmit, ...rest }) => (
        <pre
          style={{
            fontSize: ".65rem",
            padding: ".25rem .5rem",
            overflowX: "scroll"
          }}
        >
          {JSON.stringify(rest, null, 2)}
        </pre>
      )}
    </FormikConsumer>
  </div>
);

export { DebugFormik, useApiObject, useDataApi, getUniqueId };
