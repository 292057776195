import { Field } from "formik";
import * as Yup from "yup";
import pick from "lodash/pick";
import {
  BulmaTextField,
  BulmaTextAreaField,
  BulmaYearField
} from "js/components/common/BulmaFormField";

const EventForm = ({ organization }) => {
  const initialValues = pick(
    organization,
    "name",
    "description",
    "website",
    "location",
    "founded",
    "audience_text",
    "asks_text",
    "offers_text",
    "instagram",
    "twitter",
    "facebook"
  );
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    website: Yup.string()
      .url()
      .required(),
    description: Yup.string().required()
  });
  const Fields = props => {
    return (
      <>
        <Field name="name" label="Brand name" component={BulmaTextField} />
        <Field
          name="website"
          type="url"
          label="What is your website?"
          component={BulmaTextField}
        />
        <Field
          name="description"
          label="Describe your event or community"
          component={BulmaTextAreaField}
        />
        <Field
          name="location"
          label="Where are you based?"
          component={BulmaTextField}
        />
        <Field
          name="founded"
          label="When was your company founded?"
          component={BulmaYearField}
        />
        <Field
          name="audience_text"
          label="Who is your audience?"
          component={BulmaTextField}
        />
        <Field
          name="asks_text"
          label="What are you looking for in a partnership?"
          component={BulmaTextField}
        />
        <Field
          name="offers_text"
          label="What can you bring to a partnership?"
          component={BulmaTextField}
        />
        <Field
          name="instagram"
          label="What is your Instagram handle?"
          component={BulmaTextField}
          prefix="@"
        />
        <Field
          name="twitter"
          label="What is your Twitter handle?"
          component={BulmaTextField}
          prefix="@"
        />
        <Field
          name="facebook"
          label="What is your Facebook handle?"
          component={BulmaTextField}
          prefix="facebook.com/"
        />
      </>
    );
  };
  return { initialValues, validationSchema, Fields };
};

export { EventForm };
