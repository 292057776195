// Import webpacked CSS
import "css/main.scss";

// Import all images so they get webpacked and can be used in django templates
function importAll(r) {
  return r.keys().map(r);
}
const images = importAll(
  require.context("./", true, /\.(png|woff|woff2|svg|eot|ttf|gif|jpe?g|ico)$/)
);

// Expose React components
require("expose-loader?Components!./js/components");
