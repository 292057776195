/*******************************************************
  Search Page
*******************************************************/

import React from "react";
import { QueryParamProvider } from "use-query-params";
import { BrowserRouter as Router, Route } from "react-router-dom";
import App from "./App";
import WindowContextWrapper from "./WindowContextWrapper";

const Search = props => {
  return (
    <Router>
      <QueryParamProvider ReactRouterRoute={Route}>
        <WindowContextWrapper>
          <App {...props} />
        </WindowContextWrapper>
      </QueryParamProvider>
    </Router>
  );
};

export { Search };
