import React, { useState } from "react";
import { Formik, Form } from "formik";
import classNames from "classnames";

const EditButton = ({ editing, onClick }) => (
  <>
    {editing ? (
      <button className="profile__infocard-close" onClick={onClick}>
        <span className="profile__infocard-close-box">
          <span className="profile__infocard-close-inner" />
        </span>
      </button>
    ) : (
      <button className="tw-button profile__infocard-edit" onClick={onClick}>
        <i className="fas fa-pencil-alt" /> <span>Edit</span>
      </button>
    )}
  </>
);

const EditableCard = ({ initialValues, handleSubmit, canEdit, render }) => {
  const [editing, setEditing] = useState(false);

  const toggleEditing = () => {
    setEditing(!editing);
  };
  const onSubmit = (values, actions) => {
    return handleSubmit(values)
      .then(() => {
        actions.resetForm();
        setEditing(false);
      })
      .catch(error => {
        actions.setErrors(error.response.data);
      })
      .finally(() => actions.setSubmitting(false));
  };
  return (
    <>
      {editing && <div className="profile__infocard-overlay" />}

      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {formik => (
          <div
            className={classNames("profile__infocard", { editing: editing })}
          >
            {canEdit && (
              <EditButton
                editing={editing}
                onClick={() => {
                  toggleEditing();
                  formik.resetForm();
                }}
              />
            )}
            <Form>
              {render({ editing, formik })}
              {editing && (
                <div className="tw-flex tw-justify-start tw-mt-12 tw-mb-4 tw-px-8">
                  <button
                    type="submit"
                    disabled={
                      !formik.dirty || !formik.isValid || formik.isSubmitting
                    }
                    className="tw-button cta profile__infocard-save"
                  >
                    Save
                  </button>
                </div>
              )}
            </Form>
          </div>
        )}
      </Formik>
    </>
  );
};

const EditableInfoCard = ({
  title,
  icon,
  initialValues,
  handleSubmit,
  canEdit,
  render
}) => {
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => setExpanded(!expanded);
  return (
    <EditableCard
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      canEdit={canEdit}
      render={({ editing, formik }) => (
        <>
          <div className="profile__infocard-header">
            <div className="tw-flex tw-justify-start">
              <h3 className="tw-flex tw-items-center tw-m-0">
                <span>{title}</span>
                <img className="tw-w-6 tw-h-6 tw-ml-4" src={icon} alt="" />
              </h3>
            </div>
            <button
              type="button"
              className={classNames("profile__infocard-toggle", {
                collapsed: !expanded
              })}
              onClick={toggleExpanded}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="12"
                overflow="visible"
                className="tw-text-parsnip-rhubarb tw-stroke-current"
                fill="none"
                strokeWidth="1"
                strokeLinecap="round"
              >
                <path d="M0,0 L12,12" />
                <path d="M12,12 L24,0" />
              </svg>
            </button>
          </div>
          <div
            className={classNames("profile__infocard-body", {
              hidden: !expanded
            })}
          >
            {render({ editing, formik })}
          </div>
        </>
      )}
    />
  );
};

export { EditButton, EditableCard, EditableInfoCard };
