import React from "react";
import { Formik, Form } from "formik";

import axios from "js/components/common/axios";
import { BrandForm } from "./Brand";
import { InfluencerForm } from "./Influencer";
import { EventForm } from "./Event";
import { ServiceForm } from "./Service";
import { SpaceForm } from "./Space";
import { DebugFormik } from "js/components/common/helpers";

const formDict = {
  brand: BrandForm,
  influencer: InfluencerForm,
  event: EventForm,
  service_provider: ServiceForm,
  space: SpaceForm
};

const Edit = ({
  urls,
  organization,
  match_types,
  certifications,
  product_certifications,
  influencer_topics
}) => {
  const { initialValues, validationSchema, Fields } = formDict[
    organization.type
  ]({
    organization,
    match_types,
    certifications,
    product_certifications,
    influencer_topics
  });
  return (
    <>
      <section>
        <div className="tw-max-w-3xl tw-mx-auto">
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              return axios
                .patch(urls.update, values)
                .then(response => {
                  if (response.data.redirect) {
                    window.location = response.data.redirect;
                  }
                  return response;
                })
                .catch(error => {
                  actions.setErrors(error.response.data);
                });
            }}
            render={props => (
              <>
                <Form>
                  <Fields {...props} />
                  <button type="submit" className="button is-primary tw-mt-4">
                    Update
                  </button>
                </Form>
                {/* <DebugFormik /> */}
              </>
            )}
          />
        </div>
      </section>
    </>
  );
};

export { Edit };
