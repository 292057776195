import React from "react";
import PropTypes from "prop-types";
import {
  setPropTypes,
  withProps,
  defaultProps,
  withStateHandlers,
  withHandlers,
  lifecycle,
  pure,
  compose
} from "recompose";
import { Form } from "formik";
import isEmpty from "lodash/isEmpty";
import classNames from "classnames";
import styled from "@emotion/styled";
// import { DebugFormik } from "js/components/common/helpers";

const ButtonBar = styled("div")`
  margin-top: 3rem;
  display: flex;
  & > * {
    flex: 1 1 auto;
  }
  & > :not(:last-child) {
    margin-right: 0.5rem;
  }
`;

const Page = ({ children }) => {
  const fields = React.Children.toArray(children);

  // Autofocus the first field on the page
  const firstField = React.cloneElement(fields[0], { autofocus: true });
  fields[0] = firstField;

  return <div>{fields}</div>;
};

// Needs to include formik props that we need to use
const withPropTypes = setPropTypes({
  handleSubmit: PropTypes.func,
  showControls: PropTypes.bool,
  getNextPage: PropTypes.func,
  getPrevPage: PropTypes.func,

  // Props from Formik
  validateForm: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  submitCount: PropTypes.number.isRequired,
  values: PropTypes.shape().isRequired,
  errors: PropTypes.shape().isRequired
});

const withPageProps = withProps(props => {
  const pages = [];
  props.pages.map(page => {
    // Iterate over the page fields to get the field names
    // This is so we can touch all visible page fields when advancing
    const fieldNames = [];
    React.Children.toArray(page.props.children).map(field => {
      fieldNames.push(field.props.name);
    });
    pages.push({
      component: page,
      fieldNames: fieldNames
    });
  });
  return {
    pages
  };
});

// Show controls by default
const withDefaultProps = defaultProps({
  showControls: true
});

const withPageState = withStateHandlers(() => ({ pageIndex: 0 }), {
  setPageIndex: () => value => ({ pageIndex: value })
});

// Create props that indicate which pages are valid and when to show the submit button
const withStatusProps = withProps(props => {
  const pageValid = {};
  props.pages.map((page, index) => {
    const currentFields = page.fieldNames;
    const errorFields = Object.keys(props.errors);
    const isValid = isEmpty(
      currentFields.filter(field => errorFields.includes(field))
    );
    pageValid[index] = isValid;
  });
  const errorPages = Object.keys(pageValid).filter(index => !pageValid[index]);
  const showSubmit = props.pageIndex == props.pages.length - 1;
  return { showSubmit, pageValid, minErrorPage: Math.min(...errorPages) };
});

const withLifecycleMethods = lifecycle({
  // Have to run validation on mount to make the pageValid prop accurate if no fields are touched when Next is clicked
  componentDidMount() {
    this.props.validateForm();
  },
  // Handle new error props passed from above (from Formik)
  // Right now we only want to set the pageIndex if a submission has been attempted
  componentDidUpdate({ submitCount, minErrorPage }) {
    if (
      minErrorPage !== this.props.minErrorPage &&
      this.props.minErrorPage >= 0 &&
      this.props.minErrorPage < this.props.pages.length - 1 &&
      submitCount > 0
    ) {
      this.props.setPageIndex(this.props.minErrorPage);
    }
  }
});

const withFormHandlers = withHandlers({
  nextPage: props => () => {
    // Touch all active fields so error messages will appear
    // This also validates the form if validateOnBlur is true
    props.pages[props.pageIndex].fieldNames.map(field =>
      props.setFieldTouched(field)
    );
    if (props.pageValid[props.pageIndex]) {
      // TODO: send data to backend API in callback by pulling values using activeFields as keys

      // If there is a getNextPage function prop, use that
      let nextPageIndex = null;
      if (props.getNextPage) {
        nextPageIndex = props.getNextPage(props.pageIndex);
      } else {
        nextPageIndex = Math.min(props.pageIndex + 1, props.pages.length - 1);
      }
      props.setPageIndex(nextPageIndex);
      window.scrollTo(0, 0);
    }
  },
  prevPage: props => () => {
    let prevPageIndex = null;
    if (props.getPrevPage) {
      prevPageIndex = props.getPrevPage(props.pageIndex);
    } else {
      prevPageIndex = Math.max(props.pageIndex - 1, 0);
    }
    props.setPageIndex(prevPageIndex);
  }
});

const WizardPure = ({
  pages,
  pageIndex,
  nextPage,
  prevPage,
  showSubmit,
  isSubmitting,
  handleSubmit,
  showControls
}) => {
  return (
    <>
      <Form>
        {pages[pageIndex].component}
        {!!showControls && (
          <ButtonBar>
            <button
              type="button"
              className="tw-button large tw-border-none"
              disabled={isSubmitting || pageIndex === 0}
              onClick={prevPage}
            >
              Back
            </button>
            {!showSubmit && (
              <button
                type="button"
                className="tw-button large"
                disabled={isSubmitting || showSubmit}
                onClick={nextPage}
              >
                Next
              </button>
            )}
            {showSubmit && (
              <button
                type="button"
                className={classNames("tw-button cta large", {
                  "is-loading": isSubmitting
                })}
                disabled={isSubmitting}
                onClick={handleSubmit}
              >
                Finish
              </button>
            )}
          </ButtonBar>
        )}
      </Form>
    </>
  );
};

const Wizard = compose(
  withPropTypes,
  withPageProps,
  withDefaultProps,
  withPageState,
  withStatusProps,
  withLifecycleMethods,
  withFormHandlers,
  pure
)(WizardPure);

export { Wizard, Page };
