import React from "react";
import PropTypes from "prop-types";
import { FormContext, useForm } from "react-hook-form";
import * as Yup from "yup";
import { useDataApi } from "js/components/common/helpers";
import {
  TextField,
  TextAreaField,
  TagArrayField,
  BooleanTagField,
  DateField
} from "js/components/common/forms/fields";

const defaultValues = {
  activities: "",
  certifications: [],
  product_certifications: [],
  themes: [],
  platforms: [],
  copy_organization: null,
  photos_required: null,
  photos_organization: null,
  product_shipping_required: null,
  product_recipient_organization: null,
  product_recipient_address: "",
  product_purchase_required: null,
  go_live: null,
  name: ""
};

const Update = ({ campaign, ...props }) => {
  const formMethods = useForm({
    defaultValues: { ...defaultValues, ...campaign },
    validationSchema: Yup.object({
      activities: Yup.string().required(),
      certifications: Yup.array().min(1),
      product_certifications: Yup.array().min(1),
      themes: Yup.array().min(1),
      platforms: Yup.array().min(1),
      photos_required: Yup.boolean().required(),
      product_shipping_required: Yup.boolean().required(),
      product_purchase_required: Yup.boolean().when(
        "product_shipping_required",
        {
          is: val => !val,
          then: Yup.boolean().required()
        }
      ),
      copy_organization: Yup.mixed().required(),
      photos_organization: Yup.mixed().when("photos_required", {
        is: val => val,
        then: Yup.mixed().required()
      }),
      product_recipient_organization: Yup.mixed().when(
        "product_shipping_required",
        {
          is: val => val,
          then: Yup.mixed().required()
        }
      ),
      product_recipient_address: Yup.string().when(
        "product_recipient_organization",
        {
          is: val => val == campaign.owner.id,
          then: Yup.string().required()
        }
      ),
      go_live: Yup.date().required(),
      name: Yup.string().required()
    })
  });
  const [{ isLoading, isError, data }, sendUpdate] = useDataApi(
    { url: props.urls.update, method: "patch" },
    {
      onSuccess: result => {
        window.location = campaign.detail_url;
      },
      onError: error => {
        console.error("Campaign update error");
        console.dir(error);
        throw error;
      }
    }
  );

  const responsibleOrgOptions = [
    { id: 0, name: "Me", slug: campaign.owner.id },
    { id: 1, name: "Potential partner(s)", slug: "" }
  ];

  const photosRequired = formMethods.watch("photos_required");
  const shippingRequired = formMethods.watch("product_shipping_required");
  const shippingOrgField = formMethods.watch("product_recipient_organization");
  const shippingOrgMe =
    shippingRequired && shippingOrgField == campaign.owner.id;
  const onSubmit = values => {
    sendUpdate(values);
  };
  return (
    <div className="tw-max-w-4xl tw-mx-auto tw-mb-12">
      <FormContext {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <section className="tw-px-8">
            <h3>Overview</h3>
            <TextField
              name="name"
              label="What would you like to call your campaign?"
            />
            <DateField
              name="go_live"
              label="What date would you like your campaign to go live?"
            />
            <TagArrayField
              name="activities"
              label="What do you want to do with a partner?"
              options={props.match_types}
              helpText="Select one"
              multiple={false}
            />
            <TagArrayField
              name="platforms"
              label="Select the platforms you'd like the final output of this campaign to be created for"
              options={props.platforms}
              helpText={"Select all that apply"}
            />
          </section>

          <section className="tw-px-8">
            <h3>Values and Themes</h3>
            <TagArrayField
              name="certifications"
              label="What organizational values would you like your campaign to be aligned around?"
              options={props.certifications}
              helpText="Select all that apply"
            />
            <TagArrayField
              name="product_certifications"
              label="What product attributes would you like your campaign to be aligned around?"
              options={props.product_certifications}
              helpText={"Select all that apply"}
            />
            <TagArrayField
              name="themes"
              label="What themes would you like your campaign to be aligned around?"
              options={props.topics}
              helpText={"Select all that apply"}
            />
          </section>

          <section className="tw-px-8">
            <h3>Logistics</h3>
            <TagArrayField
              name="copy_organization"
              label="Who would you like to be responsible for campaign copy?"
              options={responsibleOrgOptions}
              multiple={false}
            />
            <BooleanTagField
              name="photos_required"
              label="Are photos necessary of all products involved (photo taken of products together)?"
            />
            {photosRequired && (
              <TagArrayField
                name="photos_organization"
                label="Who would you like to be responsible for product photography?"
                options={responsibleOrgOptions}
                multiple={false}
              />
            )}
            <BooleanTagField
              name="product_shipping_required"
              label="Will product need to be shipped (either to you or to potential partners)?"
            />
            {shippingRequired && (
              <TagArrayField
                name="product_recipient_organization"
                label="Who would you like to receive product?"
                options={responsibleOrgOptions}
                multiple={false}
              />
            )}
            {!shippingRequired && (
              <BooleanTagField
                name="product_purchase_required"
                label="Are you expecting your partner(s) to purchase product (locally or ecomm) for photography?"
              />
            )}
            {shippingOrgMe && (
              <TextAreaField
                name="product_recipient_address"
                label="To what address will product need to be shipped?"
                rows={3}
              />
            )}
          </section>
          <div className="tw-px-8">
            <button type="submit" className="tw-button large cta">
              Update
            </button>
          </div>
        </form>
      </FormContext>
    </div>
  );
};

Update.propTypes = {
  campaign: PropTypes.shape({}),
  urls: PropTypes.shape({
    update: PropTypes.string.isRequired
  })
};

export default Update;
