import { Field } from "formik";
import * as Yup from "yup";
import pick from "lodash/pick";
import {
  BulmaTextField,
  BulmaTextAreaField,
  BulmaCheckboxField,
  BulmaYearField,
  BulmaFieldArray
} from "js/components/common/BulmaFormField";

const BrandForm = ({
  organization,
  match_types,
  certifications,
  product_certifications
}) => {
  const initialValues = pick(
    organization,
    "name",
    "description",
    "website",
    "asks",
    "certifications",
    "product_certifications",
    "location",
    "geography_growth",
    "founded",
    "product_text",
    "support",
    "dealbreakers",
    "instagram",
    "twitter",
    "facebook",
    "consent"
  );
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    website: Yup.string()
      .url()
      .required(),
    description: Yup.string().required(),
    asks: Yup.array().min(1)
  });
  const Fields = props => {
    return (
      <>
        <Field name="name" label="Company name" component={BulmaTextField} />
        <Field
          name="website"
          type="url"
          label="What is your company website?"
          component={BulmaTextField}
        />
        <Field
          name="description"
          label="Please provide a brief description of your brand."
          component={BulmaTextAreaField}
        />
        <Field
          name="product_text"
          label="What products does your brand make?"
          component={BulmaTextField}
        />
        <BulmaFieldArray
          name="asks"
          label="What types of opportunities are you interested in?"
          options={match_types}
          helpText="Select all that apply"
          form={props}
        />
        <Field
          name="location"
          label="Where is your company headquartered?"
          component={BulmaTextField}
        />
        <Field
          name="geography_growth"
          label="Where are you hoping to expand your business?"
          component={BulmaTextField}
        />
        <Field
          name="founded"
          label="When was your company founded?"
          component={BulmaYearField}
        />
        <Field
          name="support"
          label="What do you support?"
          component={BulmaTextField}
        />
        <Field
          name="dealbreakers"
          label="What do you consider dealbreakers in a partnership?"
          component={BulmaTextField}
        />
        <BulmaFieldArray
          name="certifications"
          label="What organizational certifications does your brand have?"
          options={certifications}
          helpText="Select all that apply"
          form={props}
        />
        <BulmaFieldArray
          name="product_certifications"
          label="What product certifications does your brand have?"
          options={product_certifications}
          helpText="Select all that apply"
          form={props}
        />

        <Field
          name="instagram"
          label="What is your Instagram username?"
          component={BulmaTextField}
          prefix="@"
        />
        <Field
          name="twitter"
          label="What is your Twitter username?"
          component={BulmaTextField}
          prefix="@"
        />
        <Field
          name="facebook"
          label="What is your Facebook username?"
          component={BulmaTextField}
          prefix="facebook.com/"
        />
        <Field
          name="consent"
          label="Can we use your name and logo for promotional purposes?"
          component={BulmaCheckboxField}
        />
      </>
    );
  };
  return { initialValues, validationSchema, Fields };
};

export { BrandForm };
