/*******************************************************
  Menu
*******************************************************/

import React, { useContext, useState } from "react";
import styled from "styled-components";
import classNames from "classnames";
import WindowContext from "./WindowContext";

const MenuWrapper = styled.div`
  height: 4rem;
  background: white;
`;

const MenuItem = styled.div``;

const More = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const MoreBox = styled.div``;

export default props => {
  const [selectedItem, setSelectedItem] = useState(props.active || "all");
  const [showMore, toggleShowMore] = useState(false);

  const screenSizes = useContext(WindowContext);

  const mainMenuSize = screenSizes.laptopUp
    ? props.types.length
    : screenSizes.tabletUp
    ? 3
    : 2;
  const remainderMenuSize = props.types.length - mainMenuSize;

  // Hide the menu entirely if there's only one org type to choose from
  const showMenu = props.types.length > 1;

  const selectMenu = e => {
    setSelectedItem(e.target.dataset.selected);
    props.setOrganization(e.target.dataset.selected);
  };

  return (
    <>
      {showMenu ? (
        <MenuWrapper className="tw-text-parsnip-charcoal">
          <div className="tw-max-w-6xl tw-my-0 tw-mx-auto tw-px-6 tw-h-full">
            <div className="tw-flex tw-h-full tw-text-base">
              {
                <>
                  {props.types.slice(0, mainMenuSize).map((item, index) => (
                    <MenuItem
                      key={index}
                      className={classNames("searchpage__menuitem", "main", {
                        active: item.slug === selectedItem
                      })}
                      data-selected={item.slug}
                      onClick={selectMenu}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                  {remainderMenuSize > 0 && (
                    <More onClick={() => toggleShowMore(!showMore)}>
                      More ⌄
                      {showMore && (
                        <MoreBox className="searchpage__moremenu">
                          {props.types
                            .slice(mainMenuSize)
                            .map((item, index) => (
                              <MenuItem
                                key={index}
                                className={classNames(
                                  "searchpage__menuitem",
                                  "remainder",
                                  {
                                    active: item.slug === selectedItem
                                  }
                                )}
                                data-selected={item.slug}
                                onClick={selectMenu}
                              >
                                {item.name}
                              </MenuItem>
                            ))}
                        </MoreBox>
                      )}
                    </More>
                  )}
                </>
              }
            </div>
          </div>
        </MenuWrapper>
      ) : (
        <></>
      )}
    </>
  );
};
