import React from "react";
import { pure, compose } from "recompose";
import { TagList } from "js/components/common/Widgets";

const BrandProfilePure = ({
  organization,
  permissions: { is_authenticated },
  urls
}) => (
  <>
    <div className="tw-my-4 tw-p-4 tw-border tw-rounded tw-border-gray-400">
      <div className="profilepage__info-heading">Brand Brief</div>

      <div className="profilepage__info">
        <div className="profilepage__info-key">Who we are</div>
        <div className="profilepage__info-value">
          <span>{organization.description}</span>
        </div>
      </div>

      <div className="profilepage__info">
        <div className="profilepage__info-key">Products</div>
        <div className="profilepage__info-value">
          <span>{organization.product_text}</span>
        </div>
      </div>
      {!!is_authenticated && (
        <>
          <div className="profilepage__info">
            <div className="profilepage__info-key">About our products</div>
            <div className="profilepage__info-value">
              <TagList>
                {organization.product_certifications &&
                  organization.product_certifications.map(cert => (
                    <span key={cert.id} className="tw-tag tw-bg-teal-600">
                      {cert.name}
                    </span>
                  ))}
              </TagList>
            </div>
          </div>
          <div className="profilepage__info">
            <div className="profilepage__info-key">About our company</div>
            <div className="profilepage__info-value">
              <TagList>
                {organization.certifications &&
                  organization.certifications.map(cert => (
                    <span key={cert.id} className="tw-tag">
                      {cert.name}
                    </span>
                  ))}
              </TagList>
            </div>
          </div>
        </>
      )}
    </div>

    <div className="tw-my-4 tw-p-4 tw-border tw-rounded tw-border-gray-400 tw-relative">
      {!is_authenticated && (
        <div className="profilepage__overlay">
          <a
            href={urls.join}
            className="profilepage__overlay__text tw-text-2xl tablet-up:tw-text-4xl tw-text-gray-700 tw-font-semibold"
          >
            Join Parsnip to see more!
          </a>
        </div>
      )}
      <div className="profilepage__info-heading">Reach out to us for</div>

      <div className="profilepage__info">
        <div className="profilepage__info-key">Types of opportunities</div>
        <div className="profilepage__info-value">
          <TagList>
            {organization.asks &&
              organization.asks.map(ask => (
                <span key={ask.id} className="tw-tag tw-bg-blue-600">
                  {ask.name}
                </span>
              ))}
          </TagList>
        </div>
      </div>
      <div className="profilepage__info">
        <div className="profilepage__info-key">Who/what we like to support</div>
        <div className="profilepage__info-value">{organization.support}</div>
      </div>
      <div className="profilepage__info">
        <div className="profilepage__info-key">Dealbreakers</div>
        <div className="profilepage__info-value">
          {organization.dealbreakers}
        </div>
      </div>
      <div className="profilepage__info">
        <div className="profilepage__info-key">
          Regions in which we're looking to grow
        </div>
        <div className="profilepage__info-value">
          {organization.geography_growth}
        </div>
      </div>
    </div>
  </>
);

const BrandProfile = compose(pure)(BrandProfilePure);

export default BrandProfile;
