import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useReducer,
  useRef
} from "react";
import { MemoryRouter as Router, Route, useHistory } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import classNames from "classnames";
import {
  StateMachineProvider,
  createStore,
  useStateMachine,
  DevTool
} from "little-state-machine";

import { useForm, FormContext } from "react-hook-form";
import styled from "@emotion/styled";
import { useDataApi } from "js/components/common/helpers";

const ButtonBar = styled("div")`
  margin-top: 3rem;
  display: flex;
  & > * {
    flex: 1 1 auto;
  }
  & > :not(:last-child) {
    margin-right: 0.5rem;
  }
`;

createStore({
  wizardData: {}
});
const updateDataAction = (state, payload) => {
  return {
    ...state,
    wizardData: {
      ...state.wizardData,
      ...payload
    }
  };
};
const deleteDataAction = (state, payload) => {
  return { ...state, wizardData: {} };
};
const liveFieldsReducer = (liveFields, action) => {
  switch (action.type) {
    case "add":
      return {
        ...liveFields,
        ...Object.keys(action.values).reduce(
          (addObj, addKey) => ({ ...addObj, [addKey]: action.pageNum }),
          {}
        )
      };

    case "remove":
      const result = Object.keys(liveFields)
        .filter(field => liveFields[field] != action.pageNum)
        .reduce(
          (subObj, subKey) => ({ ...subObj, [subKey]: liveFields[subKey] }),
          {}
        );
      return result;
    default:
      throw new Error();
  }
};

const WizardContext = createContext(null);
const WizardInnerContext = createContext(null);

const Page = ({ defaultValues, validationSchema, getNextPage, children }) => {
  const { state, actions } = useStateMachine({
    updateDataAction
  });
  // Form default values should be a merge of the Page default values and the existing state data
  const formMethods = useForm({
    defaultValues: { ...defaultValues, ...state.wizardData },
    mode: "onSubmit",
    validationSchema
  });
  const history = useHistory();

  // Local state (TODO: use useReducer?)
  // const [canGoBack, setCanGoBack] = useState(() => {
  //   // Can only go back if we have pushed new pages onto the history
  //   return history.index > 0;
  // });
  const [isLastPage, setIsLastPage] = useState(() => {
    const values = formMethods.getValues();
    const nextPage = getNextPage(values);
    return !nextPage;
  });
  const {
    liveFields,
    liveFieldsDispatch,
    submissionErrors,
    setSubmissionErrors
  } = useContext(WizardContext);
  const { handleSubmit, isLoading } = useContext(WizardInnerContext);

  // Update RHF errors based on WizardContext submission errors
  // TODO: This feels really brittle. Please fix.
  const isFirstRun = useRef(true);
  const hasSetSubmissionErrors = useRef(false);
  const hasReValidatedSubmissionErrors = useRef(false);
  useEffect(() => {
    if (
      hasSetSubmissionErrors.current &&
      !hasReValidatedSubmissionErrors.current
    ) {
      // Don't run this the first time the form re-loads after submission
      if (isFirstRun.current) {
        isFirstRun.current = false;
        return;
      }
      // Re-validate inputs onChange to clear submission errors
      formMethods.triggerValidation();
      hasReValidatedSubmissionErrors.current = true;
    }

    if (!isEmpty(submissionErrors)) {
      const errorType = "error";
      // For submissionErrors fields that are:
      Object.keys(submissionErrors)
        // - in set of live fields
        .filter(fieldName => Object.keys(liveFields).includes(fieldName))
        // - on the current page (which will be the first page with an error)
        .filter(fieldName =>
          Object.keys(formMethods.getValues()).includes(fieldName)
        )
        // we set the error on the form for that field
        .map(fieldName =>
          submissionErrors[fieldName].map(errorMessage => {
            formMethods.setError(fieldName, errorType, errorMessage);
          })
        );
      setSubmissionErrors({});
      hasSetSubmissionErrors.current = true;
    }
  }, [submissionErrors, formMethods.watch()]);

  // Enable/disable back/forward buttons based on current location and current form state
  useEffect(() => {
    // const backEnabled = history.index > 0;
    // setCanGoBack(backEnabled);

    const values = formMethods.getValues();
    const nextPage = getNextPage(values);
    const isLastPage = !nextPage;
    setIsLastPage(isLastPage);
  }, [
    //  formMethods.formState.isValid,
    formMethods.watch(),
    history
  ]);

  const onNext = values => {
    actions.updateDataAction(values);

    liveFieldsDispatch({ type: "add", values, pageNum: history.index });

    // Get the name of the next page and go
    const nextPage = getNextPage(values);
    if (nextPage) {
      history.push(`/${nextPage.name}`);
      window.scrollTo(0, 0);
    }
    // Otherwise submit the data
    else {
      // NOTE: need to add the current fields to liveFields and the state object since
      // the little-state-machine action and the reducer action won't have completed yet (apparently)
      const finalLiveFields = {
        ...liveFields,
        ...Object.keys(values).reduce(
          (addObj, addKey) => ({
            ...addObj,
            [addKey]: history.index
          }),
          {}
        )
      };
      const finalWizardData = {
        ...state.wizardData,
        ...values
      };
      const finalData = Object.keys(finalWizardData)
        .filter(key => Object.keys(finalLiveFields).includes(key))
        .reduce((obj, key) => ({ ...obj, [key]: finalWizardData[key] }), {});
      handleSubmit(finalData);
    }
  };

  const onBackClicked = values => {
    liveFieldsDispatch({ type: "remove", pageNum: history.index });
    history.goBack();
    window.scrollTo(0, 0);
  };

  return (
    <FormContext {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onNext)}>
        <>
          {children}
          <ButtonBar>
            <button
              type="button"
              className="tw-button large tw-border-none"
              onClick={() => onBackClicked(formMethods.getValues())}
              disabled={history.index == 0 || isLoading}
            >
              Prev
            </button>
            <button
              type="submit"
              className={classNames("tw-button large", {
                cta: isLastPage,
                "is-loading": isLoading
              })}
              disabled={isLoading}
            >
              {isLastPage ? "Submit" : "Next"}
            </button>
          </ButtonBar>
        </>
      </form>
    </FormContext>
  );
};

const WizardCore = ({ children, ...props }) => {
  const { state, actions } = useStateMachine({
    deleteDataAction
  });
  const history = useHistory();
  const {
    submissionConfig,
    liveFields,
    liveFieldsDispatch,
    setSubmissionErrors,
    onSubmit
  } = useContext(WizardContext);
  const [{ isLoading, isError, data }, submitData] = useDataApi(
    submissionConfig,
    {
      onSuccess: result => {
        actions.deleteDataAction();
        onSubmit(result);
      },
      onError: error => {
        // TODO: Handle this error
        if (isEmpty(error.response) || isEmpty(error.response.data)) {
          throw new Error("Server error on submission");
        }

        // TODO: better handle multiple errors - currently this will only show one error at a time for a given field

        // Find the first page containing a field with an error and properly go back in history to that page
        // This includes removing the later page fields from the liveFields state
        const errorPages = Object.values(
          Object.keys(liveFields)
            .filter(field => Object.keys(error.response.data).includes(field))
            .reduce((obj, key) => ({ ...obj, [key]: liveFields[key] }), {})
        );
        if (errorPages) {
          const minErrorPage = errorPages.reduce(
            (min, page) => (page < min ? page : min),
            errorPages[0]
          );

          // Call the 'remove' reducer the correct number of times to go back to the first error page
          Array.from({
            length: history.index - minErrorPage
          }).map(_ => {
            liveFieldsDispatch({ type: "remove", pageNum: history.index });
            history.goBack();
          });

          // Set errors in the Wizard context so they will be rendered in the correct page fields
          setSubmissionErrors(error.response.data);
        }
      }
    }
  );
  const handleSubmit = data => {
    submitData(data);
  };
  return (
    <>
      {React.Children.map(children, (PageComponent, index) => {
        const PageWithProps = React.cloneElement(PageComponent, {
          // Need to include values as props so that e.g. InfluencerPage4 can use type to determine options
          values: state.wizardData,
          ...props
        });
        return (
          <WizardInnerContext.Provider value={{ handleSubmit, isLoading }}>
            <Route
              exact
              key={index}
              path={`/${PageComponent.type.name}`}
              render={() => {
                return PageWithProps;
              }}
            />
          </WizardInnerContext.Provider>
        );
      })}
    </>
  );
};

const Wizard = ({
  initialPage,
  submissionConfig,
  onSubmit,
  children,
  ...props
}) => {
  // liveFields is an object with shape { fieldName:pageIndex }
  const [liveFields, liveFieldsDispatch] = useReducer(liveFieldsReducer, {});
  // error state used for passing errors to pages after submission
  const [submissionErrors, setSubmissionErrors] = useState({});

  const initialPath = `/${initialPage.name}`;
  return (
    <StateMachineProvider>
      <WizardContext.Provider
        value={{
          submissionConfig,
          onSubmit,
          liveFields,
          liveFieldsDispatch,
          submissionErrors,
          setSubmissionErrors
        }}
      >
        <Router initialEntries={[initialPath]}>
          <WizardCore {...props}>{children}</WizardCore>
        </Router>
      </WizardContext.Provider>
    </StateMachineProvider>
  );
};

export { Page, Wizard };
