import React from "react";
import * as moment from "moment";

import { useDataApi } from "js/components/common/helpers";

import {
  Tag,
  TagList,
  WhiteCard,
  Logo,
  SocialIcon,
  Label,
  Modal
} from "js/components/common/Widgets";
import twitterGlyph from "images/profile/twitter-glyph.svg";
import instagramGlyph from "images/profile/instagram-glyph.svg";
import facebookGlyph from "images/profile/facebook-glyph.svg";

const CampaignInfo = ({ campaign, ...props }) => (
  <>
    <div className="campaign__lower-layout">
      <div className="campaign__lower-header">
        <h3 className="tw-my-0">The Campaign: {campaign.name}</h3>
        <div>{moment(campaign.go_live).format("MMMM Do, YYYY")}</div>
      </div>

      <div className="campaign__lower-details">
        <div>
          <Label>This campaign is targeting:</Label>
          <div className="tw-mt-2">
            {props.platforms
              .filter(platform => campaign.platforms.includes(platform.slug))
              .map(platform => platform.name)
              .join(", ")}
          </div>
        </div>

        <div>
          <Label>Responsibilities:</Label>
          <div className="tw-mt-2">
            {campaign.copy_organization == campaign.owner.id ? (
              <span>
                <a href={campaign.owner.profile_url}>{campaign.owner.name}</a>
                <span> will provide copy</span>
              </span>
            ) : (
              <span>Campaign partner will provide copy</span>
            )}
          </div>
          {campaign.photos_required && (
            <div className="tw-mt-2">
              {campaign.photos_organization == campaign.owner.id ? (
                <span>
                  <a href={campaign.owner.profile_url}>{campaign.owner.name}</a>
                  <span> will provide photos</span>
                </span>
              ) : (
                <span>Campaign partner will provide photos</span>
              )}
            </div>
          )}
          {campaign.product_purchase_required && (
            <div className="tw-mt-2">
              <span>
                Campaign partner will purchase products locally for photos
              </span>
            </div>
          )}
          {campaign.product_shipping_required && (
            <div className="tw-mt-2">
              {campaign.product_recipient_organization == campaign.owner.id ? (
                <span>Campaign partner will ship products for photos</span>
              ) : (
                <span>
                  <a href={campaign.owner.profile_url}>{campaign.owner.name}</a>
                  <span> will ship products for photos</span>
                </span>
              )}
            </div>
          )}
        </div>

        <div>
          <Label>Campaign timeline:</Label>
          <div className="tw-mt-2">
            <div className="campaign__lower-timeline">
              {campaign.product_shipping_required && (
                <>
                  <div className="campaign__timeline-label">
                    Shipping product to arrive by:
                  </div>
                  <div className="tw-font-semibold">
                    {moment(campaign.go_live)
                      .subtract(31, "days")
                      .format("MMMM Do")}
                  </div>
                </>
              )}
              {campaign.photos_required && (
                <>
                  <div className="campaign__timeline-label">
                    Approving photography by:
                  </div>
                  <div className="tw-font-semibold">
                    {moment(campaign.go_live)
                      .subtract(15, "days")
                      .format("MMMM Do")}
                  </div>
                </>
              )}
              <div className="campaign__timeline-label">Approving copy by:</div>
              <div className="tw-font-semibold">
                {moment(campaign.go_live)
                  .subtract(10, "days")
                  .format("MMMM Do")}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="campaign__lower-tags">
        <div>
          <Label>Campaign themes:</Label>
          <TagList className="tw-mt-4">
            {props.topics
              .filter(tag => campaign.themes.includes(tag.slug))
              .map(tag => (
                <Tag key={tag.id} label={tag.name} theme="white" />
              ))}
          </TagList>
        </div>

        <div>
          <Label>Campaign values and attributes:</Label>
          <TagList className="tw-mt-4">
            {props.certifications
              .filter(tag => campaign.certifications.includes(tag.slug))
              .map(tag => (
                <Tag key={tag.id} label={tag.name} theme="yellow" />
              ))}
            {props.product_certifications
              .filter(tag => campaign.product_certifications.includes(tag.slug))
              .map(tag => (
                <Tag key={tag.id} label={tag.name} theme="yellow" />
              ))}
          </TagList>
        </div>
      </div>
    </div>
  </>
);

const Detail = ({ campaign: initialCampaign, ...props }) => {
  const [campaign, setCampaign] = React.useState(initialCampaign);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [{ isLoading, isError, data }, sendRequest] = useDataApi(
    { url: campaign.join_url, method: "post" },
    {
      onSuccess: response => {
        setCampaign(response.data.campaign);
        setIsModalOpen(true);
      },
      onError: error => {
        console.error("Campaign join error");
        console.dir(error);
        throw error;
      }
    }
  );
  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <>
      <div className="tw-flex-1 tw-bg-gray-100">
        <div className="tw-max-w-4xl tw-mx-auto tw-px-4 tw-py-12">
          <WhiteCard>
            <h3 className="tw-mt-0">Campaign Creator</h3>
            <div className="campaign__upper-layout">
              <a href={campaign.owner.profile_url} target="_blank">
                <Logo src={campaign.owner.logo} />
              </a>
              <div>
                <a href={campaign.owner.profile_url}>
                  <Label className="tw-p-2">{campaign.owner.name}</Label>
                </a>
                <div className="tw-p-2">{campaign.owner.description}</div>
                {(campaign.owner.instagram ||
                  campaign.owner.twitter ||
                  campaign.owner.facebook) && (
                  <div className="tw-py-2">
                    {campaign.owner.instagram && (
                      <a
                        href={`https://instagram.com/${campaign.owner.instagram}`}
                        target="_blank"
                        className="tw-inline-block tw-p-2"
                      >
                        <SocialIcon src={instagramGlyph} alt="Instagram" />
                      </a>
                    )}
                    {campaign.owner.twitter && (
                      <a
                        href={`https://twitter.com/${campaign.owner.twitter}`}
                        target="_blank"
                        className="tw-inline-block tw-p-2"
                      >
                        <SocialIcon src={twitterGlyph} alt="Twitter" />
                      </a>
                    )}
                    {campaign.owner.facebook && (
                      <a
                        href={`https://facebook.com/${campaign.owner.facebook}`}
                        target="_blank"
                        className="tw-inline-block tw-p-2"
                      >
                        <SocialIcon src={facebookGlyph} alt="Facebook" />
                      </a>
                    )}
                  </div>
                )}
              </div>
            </div>
          </WhiteCard>
          <WhiteCard className="tw-mt-12">
            <CampaignInfo campaign={campaign} {...props} />
          </WhiteCard>
          <div className="tw-mt-8 laptop-up:tw-text-center">
            <button
              onClick={() => sendRequest()}
              className="tw-button cta large"
              disabled={!props.permissions.can_join || campaign.has_joined}
            >
              I am in
            </button>
          </div>
          <div className="tw-pt-8 laptop-up:tw-px-8">
            <a href={props.urls.campaigns}>Back to campaigns</a>
          </div>
        </div>
      </div>

      <Modal isOpen={isModalOpen} handleCloseModal={handleCloseModal}>
        <div className="tw-flex tw-flex-col tw-items-center tw-my-4 tw-mx-8">
          <div className="tw-font-semibold">
            Thank you for submitting your brand to this campaign!
          </div>
          <div className="tw-mt-8">
            {campaign.owner.name} will notify you via email if they are IN!
          </div>
          <a href={props.urls.campaigns} className="tw-button cta tw-mt-8">
            Back to campaigns
          </a>
        </div>
      </Modal>
    </>
  );
};
Detail.propTypes = {};

export default Detail;
export { CampaignInfo };
