/*******************************************************
  Checkbox
*******************************************************/

import React from "react";
import classNames from "classnames";

const Checkbox = props => (
  <label className="tw-cursor-pointer">
    <input
      type="checkbox"
      value={props["data-id"]}
      data-id={props["data-id"]}
      data-slug={props["data-slug"]}
      data-name={props["data-name"]}
      data-type={props["data-type"]}
      checked={props.checked}
      onChange={props.onClick}
      className="hideVisually"
    />
    <div className="tw-flex tw-items-center">
      <div
        className={classNames("searchpage__checkbox", {
          checked: props.checked
        })}
        checked={props.checked}
      ></div>
      <span className="tw-font-normal">{props.label}</span>
    </div>
  </label>
);

export default Checkbox;
