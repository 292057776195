import { Field } from "formik";
import * as Yup from "yup";
import pick from "lodash/pick";
import {
  BulmaTextField,
  BulmaTextAreaField,
  BulmaFieldArray
} from "js/components/common/BulmaFormField";

const InfluencerForm = ({ organization, influencer_topics, match_types }) => {
  const initialValues = pick(
    organization,
    "name",
    "description",
    "website",
    "location",
    "instagram",
    "twitter",
    "facebook",
    "topics",
    "offers"
  );
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    website: Yup.string()
      .url()
      .required(),
    description: Yup.string().required(),
    topics: Yup.array().min(1),
    offers: Yup.array().min(1)
  });
  const Fields = props => {
    return (
      <>
        <Field name="name" label="Brand name" component={BulmaTextField} />
        <Field
          name="website"
          type="url"
          label="What is your website?"
          component={BulmaTextField}
        />
        <Field
          name="description"
          label="Describe yourself"
          component={BulmaTextAreaField}
        />
        <Field
          name="location"
          label="Where are you based?"
          component={BulmaTextField}
        />

        <BulmaFieldArray
          name="topics"
          label="What types of topics do you cover?"
          options={influencer_topics}
          helpText="Select all that apply"
          form={props}
        />
        <BulmaFieldArray
          name="offers"
          label="What types of content do you create?"
          options={match_types}
          helpText="Select all that apply"
          form={props}
        />
        <Field
          name="instagram"
          label="What is your Instagram handle?"
          component={BulmaTextField}
          prefix="@"
        />
        <Field
          name="twitter"
          label="What is your Twitter handle?"
          component={BulmaTextField}
          prefix="@"
        />
        <Field
          name="facebook"
          label="What is your Facebook handle?"
          component={BulmaTextField}
          prefix="facebook.com/"
        />
      </>
    );
  };
  return { initialValues, validationSchema, Fields };
};

export { InfluencerForm };
