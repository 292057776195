import React from "react";
import PropTypes from "prop-types";

import * as Yup from "yup";
import styled from "@emotion/styled";
import {
  TextField,
  SelectField,
  TagArrayField
} from "js/components/common/forms/fields";
import { Page, Wizard } from "js/components/common/forms/Wizard";

const SimpleList = styled("ul")`
  list-style: none;
  padding-left: 0;
  & li {
    margin-left: 1rem;
    margin-top: 0.5rem;
  }
  & li::before {
    content: "-";
    margin-left: -1rem;
  }
`;

const CompanyPage1 = () => {
  const ValidationSchema = Yup.object({
    name: Yup.string().required()
  });
  const DefaultValues = { name: "" };
  return (
    <Page
      defaultValues={DefaultValues}
      validationSchema={ValidationSchema}
      getNextPage={() => {
        return CompanyPage2;
      }}
    >
      <TextField
        name="name"
        type="text"
        label="What is the name of your organization?"
      />
      <TextField name="title" type="text" label="What is your title?" />
    </Page>
  );
};

const CompanyPage2 = () => {
  const ValidationSchema = Yup.object({
    website: Yup.string()
      .url()
      .required(),
    instagram: Yup.string(),
    facebook: Yup.string(),
    twitter: Yup.string()
  });
  return (
    <Page
      defaultValues={{
        website: "",
        instagram: "",
        facebook: "",
        twitter: ""
      }}
      validationSchema={ValidationSchema}
      getNextPage={() => {
        return CompanyPage3;
      }}
    >
      <TextField
        name="website"
        type="url"
        label="What is your company website?"
      />
      <TextField name="instagram" label="Instagram" prefix="@" />
      <TextField name="twitter" label="Twitter" prefix="@" />
      <TextField name="facebook" label="Facebook" prefix="facebook.com/" />
    </Page>
  );
};

const CompanyPage3 = ({ org_types }) => {
  const ValidationSchema = Yup.object({
    type: Yup.string().required()
  });
  return (
    <Page
      defaultValues={{ type: null }}
      validationSchema={ValidationSchema}
      getNextPage={values => {
        let nextPage = null;
        switch (values.type) {
          case "brand":
            nextPage = BrandPage1;
            break;
          case "service_provider":
            nextPage = ServiceProviderPage1;
            break;
          case "influencer":
            nextPage = InfluencerPage1;
            break;
          case "event":
            nextPage = EventPage1;
            break;
          case "space":
            nextPage = SpacePage1;
            break;
          default:
            nextPage = BrandPage1;
        }
        return nextPage;
      }}
    >
      <SelectField
        name="type"
        label="Which category best describes your organization?"
        options={org_types}
      />
    </Page>
  );
};

const BrandPage1 = ({ certifications }) => {
  const ValidationSchema = Yup.object({
    certifications: Yup.array()
  });
  return (
    <Page
      defaultValues={{ certifications: [] }}
      validationSchema={ValidationSchema}
      getNextPage={() => {
        return BrandPage2;
      }}
    >
      <TagArrayField
        name="certifications"
        label="What labels or certifications does your organization have?"
        options={certifications}
        helpText="Select all that apply"
      />
    </Page>
  );
};
const BrandPage2 = ({ product_certifications }) => {
  const ValidationSchema = Yup.object({
    product_text: Yup.string().required(),
    product_certifications: Yup.array()
  });
  return (
    <Page
      defaultValues={{
        product_text: "", // Short answer, lso used for service
        product_certifications: []
      }}
      validationSchema={ValidationSchema}
      getNextPage={() => {
        return BrandPage3;
      }}
    >
      <TextField
        name="product_text"
        label="What products does your brand make?"
      />
      <TagArrayField
        name="product_certifications"
        label="What product labels or certifications does your brand have?"
        options={product_certifications}
        helpText="Select all that apply"
      />
    </Page>
  );
};
const BrandPage3 = ({ match_types }) => {
  const ValidationSchema = Yup.object({
    asks: Yup.array().min(1)
  });
  return (
    <Page
      defaultValues={{ asks: [] }}
      validationSchema={ValidationSchema}
      getNextPage={() => {
        return BrandPage4;
      }}
    >
      <TagArrayField
        name="asks"
        label="What types of partnerships are you interested in?"
        options={match_types}
        helpText="Select all that apply"
      />
    </Page>
  );
};
const BrandPage4 = ({ match_types }) => {
  const ValidationSchema = Yup.object({
    offers: Yup.array().min(1)
  });
  return (
    <Page
      defaultValues={{ offers: [] }}
      validationSchema={ValidationSchema}
      getNextPage={() => {}}
    >
      <TagArrayField
        name="offers"
        label="What can you offer to a partnership?"
        options={match_types}
        helpText="Select all that apply"
      />
    </Page>
  );
};
const ServiceProviderPage1 = ({ service_provider_subtypes }) => {
  const ValidationSchema = Yup.object({
    subtype: Yup.string().required(),
    product_text: Yup.string().required(),
    offers_text: Yup.string().required(),
    asks_text: Yup.string().required(),
    dream_partners: Yup.string()
  });
  return (
    <Page
      defaultValues={{
        product_text: "",
        offers_text: "",
        asks_text: "",
        dream_partners: ""
      }}
      validationSchema={ValidationSchema}
      getNextPage={() => {}}
    >
      <SelectField
        name="subtype"
        label="Which type of service provider best describes your organization?"
        options={service_provider_subtypes}
      />
      <TextField
        name="product_text"
        label="What service does your brand provide?"
      />
      <TextField
        name="offers_text"
        label="What can you bring to a partnership?"
      />
      <TextField
        name="asks_text"
        label="What are you looking for in a partnership?"
      />
      <TextField name="dream_partners" label="Who are your dream partners?" />
    </Page>
  );
};
const InfluencerPage1 = () => {
  const ValidationSchema = Yup.object({
    description: Yup.string().required(),
    dream_partners: Yup.string()
  });
  return (
    <Page
      defaultValues={{
        description: "",
        dream_partners: ""
      }}
      validationSchema={ValidationSchema}
      getNextPage={() => {
        return InfluencerPage2;
      }}
    >
      <TextField
        name="description"
        label="What should potential brand partners know about you?"
      />
      <div className="tw-mb-4 tw-text-sm tw-italic">
        <div>
          Signing up to be an Influencer in the Parsnip Community means:
        </div>
        <SimpleList>
          <li>You value authenticity in working with a brand or event</li>
          <li>
            You are an influencer working in the food, beverage, beauty,
            personal care, fitness, or lifestyle space with 5,000-50,000
            followers.
          </li>
          <li>
            You are willing to accept in-kind product donation in exchange for
            your creation of content (we are not the tool for influencers
            requiring fees)
          </li>
        </SimpleList>
      </div>
      <TextField name="dream_partners" label="Who are your dream partners?" />
    </Page>
  );
};
const InfluencerPage2 = ({ certifications }) => {
  const ValidationSchema = Yup.object({
    certifications: Yup.array().min(1)
  });
  return (
    <Page
      defaultValues={{ certifications: [] }}
      validationSchema={ValidationSchema}
      getNextPage={() => {
        return InfluencerPage3;
      }}
    >
      <TagArrayField
        name="certifications"
        label="What organizational labels and certifications would make a brand a good fit?"
        options={certifications}
        helpText="Select all that apply"
      />
    </Page>
  );
};
const InfluencerPage3 = ({ product_certifications }) => {
  const ValidationSchema = Yup.object({
    product_certifications: Yup.array().min(1)
  });
  return (
    <Page
      defaultValues={{ product_certifications: [] }}
      validationSchema={ValidationSchema}
      getNextPage={() => {
        return InfluencerPage4;
      }}
    >
      <TagArrayField
        name="product_certifications"
        label="What product certifications would make a brand a good fit?"
        options={product_certifications}
        helpText="Select all that apply"
      />
    </Page>
  );
};
const InfluencerPage4 = ({ match_types, influencer_topics, values }) => {
  const ValidationSchema = Yup.object({
    offers: Yup.array().min(1),
    topics: Yup.array().min(1)
  });
  return (
    <Page
      defaultValues={{ offers: [], topics: [] }}
      validationSchema={ValidationSchema}
      getNextPage={() => {}}
    >
      <TagArrayField
        name="offers"
        label="What types of content are you equipped to create?"
        options={match_types.filter(type => {
          return type.valid_for.includes(values.type);
        })}
        helpText="Select all that apply"
      />
      <TagArrayField
        name="topics"
        label="What topics are in your wheelhouse?"
        options={influencer_topics}
        helpText="Select all that apply"
      />
    </Page>
  );
};
const EventPage1 = ({ audiences }) => {
  const ValidationSchema = Yup.object({
    audiences: Yup.array().min(1)
  });
  return (
    <Page
      defaultValues={{ audiences: [] }}
      validationSchema={ValidationSchema}
      getNextPage={() => {}}
    >
      <TagArrayField
        name="audiences"
        label="What is your typical audience?"
        options={audiences}
        helpText="Select all that apply"
      />
    </Page>
  );
};
const SpacePage1 = () => {
  const ValidationSchema = Yup.object({
    offers_text: Yup.string().required(),
    asks_text: Yup.string().required(),
    dream_partners: Yup.string()
  });
  return (
    <Page
      defaultValues={{
        offers_text: "",
        asks_text: "",
        dream_partners: ""
      }}
      validationSchema={ValidationSchema}
      getNextPage={() => {}}
    >
      <TextField
        name="offers_text"
        label="What can you bring to a partnership?"
      />
      <TextField
        name="asks_text"
        label="What are you looking for in a partnership?"
      />
      <TextField name="dream_partners" label="Who are your dream partners?" />
    </Page>
  );
};

const Registration = props => {
  const submissionConfig = {
    url: props.registrationUrl,
    method: "post"
  };
  return (
    <>
      <div className="tw-mb-12">
        <h3>Company Information</h3>
      </div>
      <Wizard
        initialPage={CompanyPage1}
        onSubmit={response => {
          if (response.data.redirect) {
            window.location = response.data.redirect;
          }
        }}
        submissionConfig={submissionConfig}
        {...props}
      >
        <CompanyPage1 />
        <CompanyPage2 />
        <CompanyPage3 />
        <BrandPage1 />
        <BrandPage2 />
        <BrandPage3 />
        <BrandPage4 />
        <InfluencerPage1 />
        <InfluencerPage2 />
        <InfluencerPage3 />
        <InfluencerPage4 />
        <ServiceProviderPage1 />
        <EventPage1 />
        <SpacePage1 />
      </Wizard>
    </>
  );
};

Registration.propTypes = {
  registrationUrl: PropTypes.string.isRequired,
  org_types: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired
    })
  ).isRequired,
  service_provider_subtypes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired
    })
  ).isRequired,
  match_types: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired
    })
  ).isRequired,
  audiences: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired
    })
  ).isRequired,
  certifications: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired
    })
  ).isRequired,
  product_certifications: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired
    })
  ).isRequired,
  influencer_topics: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired
    })
  ).isRequired
};

export default Registration;
