import React from "react";
import * as moment from "moment";

import { Tag, TagList, WhiteCard, Logo } from "js/components/common/Widgets";

import styled from "@emotion/styled";

const CampaignCard = ({ campaign, ...props }) => {
  const campaignActivity = props.match_types.find(
    tag => tag.slug === campaign.activities[0]
  );
  const campaignThemes = props.topics.filter(tag =>
    campaign.themes.includes(tag.slug)
  );
  const campaignCertifications = props.certifications.filter(tag =>
    campaign.certifications.includes(tag.slug)
  );
  const campaignProductCertifications = props.product_certifications.filter(
    tag => campaign.product_certifications.includes(tag.slug)
  );
  return (
    <WhiteCard className="tw-mt-12">
      {campaignActivity && (
        <h4 className=" tw-mt-0 tw-font-semibold">{campaignActivity.name}</h4>
      )}

      <div className="tw-mt-6 tw-flex tw-flex-col tablet-up:tw-flex-row">
        <a href={campaign.owner.profile_url} target="_blank">
          <Logo src={campaign.owner.logo} size="6rem" />
        </a>

        <div className="tw-mt-4 tablet-up:tw-mt-0 tablet-up:tw-ml-8">
          <div className="tw-font-semibold">{`${campaign.name} with ${campaign.owner.name}`}</div>
          <div className="tw-text-base">
            {moment(campaign.go_live).format("MMMM Do, YYYY")}
          </div>
        </div>
      </div>
      <div className="tw-mt-4 tw-flex tw-flex-col tablet-up:tw-flex-row">
        <div className="tw-flex-2">
          <h4>Values</h4>
          <TagList>
            {campaignCertifications.map(tag => (
              <Tag key={tag.id} label={tag.name} theme="yellow" />
            ))}
            {campaignProductCertifications.map(tag => (
              <Tag key={tag.id} label={tag.name} theme="yellow" />
            ))}
          </TagList>
        </div>
        <div className="tw-flex-1 tw-mt-8 tablet-up:tw-mt-0 tablet-up:tw-ml-4">
          <h4>Themes</h4>
          <TagList>
            {campaignThemes.map(tag => (
              <Tag key={tag.id} label={tag.name} theme="white" />
            ))}
          </TagList>
        </div>
      </div>
      <div className="tw-mt-8 tw-flex tw-justify-start">
        <a href={campaign.detail_url} target="_blank" className="tw-button cta">
          Tell me more
        </a>
      </div>
    </WhiteCard>
  );
};

const List = ({ campaigns, ...props }) => (
  <div className="tw-flex-1 tw-bg-gray-100">
    <div className="tw-max-w-4xl tw-mx-auto tw-px-4 tw-py-12">
      <h3>Current Campaigns</h3>
      {campaigns
        .sort((f, s) => (f.go_live < s.go_live ? -1 : 1))
        .map(campaign => (
          <CampaignCard key={campaign.id} campaign={campaign} {...props} />
        ))}
    </div>
  </div>
);

export default List;
